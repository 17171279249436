<template>
  <div class="mt-3 mb-8">
    <v-expansion-panels v-model="expanded" tile accordion>
      <v-expansion-panel
        v-for="amenity in amenities"
        :key="amenity.id"
        :ref="`exp-${amenity.id}`"
        :class="{ choosen: isChoosen(amenity) }">
        <amenity-panel-header
          v-bind="amenity"
          :is-choosen="isChoosen(amenity)" />
        <v-expansion-panel-content>
          <amenity-carousel
            @contract="expanded = null"
            v-bind="amenity"
            :assets="amenity.assets" />
          <amenity-actions
            :key="amenity.id"
            @update-slots="$emit('update-slots', $event)"
            @contract="expanded = null"
            v-bind="{ amenity, isChoosen: isChoosen(amenity) }" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import AmenityActions from './Actions';
import AmenityCarousel from './Carousel';
import AmenityPanelHeader from './PanelHeader';
import { mapGetters } from 'vuex';

export default {
  name: 'amenity-list',
  props: {
    amenities: { type: Array, required: true }
  },
  data: () => ({ expanded: null }),
  computed: mapGetters('bookings', ['selectedAmenityIds']),
  methods: {
    isChoosen({ id }) {
      return this.selectedAmenityIds.includes(id);
    }
  },
  components: {
    AmenityActions,
    AmenityCarousel,
    AmenityPanelHeader
  }
};
</script>

<style lang="scss" scoped>
@import '@/main/stylesheets/vuetify';

::v-deep .v-expansion-panels--tile {
  .v-expansion-panel .actions, .v-expansion-panel::before {
    border-radius: inherit;
  }
  .v-expansion-panel:first-child .v-carousel {
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
  }
}

::v-deep .v-expansion-panels.v-expansion-panels--accordion {
  padding: 0;

  .additional-info {
    display: flex;

    .text-regular {
      color: $light-blue-grey;
      font-size: 0.5625rem;
      font-weight: 600;
    }
  }

  .v-expansion-panel-header {
    height: 4rem;

    &.hidden .v-expansion-panel-header__icon {
      display: none;
    }
  }
}
</style>
