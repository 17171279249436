<template>
  <div class="mb-4">
    <div class="slider-text mb-n3">
      <span class="primary--text font-weight-bold">{{ value }}</span>
      {{ pluralize(label, value) }}
      <template v-if="hint">({{ hint }})</template>
    </div>
    <v-slider
      ref="slider"
      @input="limitInput"
      @change="value !== $event && $emit('change', $event)"
      :track-color="$vuetify.theme.dark ? 'grey darken-1' : 'grey'"
      :value="value"
      :max="max"
      color="secondary"
      thumb-size="20"
      thumb-label hide-details>
      <template #prepend>
        <v-btn
          @click="$emit('change', value - 1)"
          :disabled="!value"
          icon outlined dark>
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </template>
      <template #append>
        <v-btn
          @click="$emit('change', value + 1)"
          :disabled="disableIncrease"
          icon outlined dark>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </v-slider>
  </div>
</template>

<script>
import pluralize from 'pluralize';

export default {
  name: 'slots-slider',
  props: {
    value: { type: Number, default: 0 },
    label: { type: String, required: true },
    hint: { type: String, default: '' },
    max: { type: Number, required: true },
    remaining: { type: Number, required: true },
    retainSlots: { type: Boolean, default: false }
  },
  computed: {
    disableIncrease() {
      const maxReached = this.value === this.max;
      const availableSlots = this.retainSlots || this.remaining;
      return maxReached || !availableSlots;
    }
  },
  methods: {
    pluralize,
    limitInput(count) {
      const change = count - this.value;
      if (change <= this.remaining || this.retainSlots) return;
      this.$refs.slider.internalValue = this.value + this.remaining;
    }
  }
};

</script>

<style lang="scss" scoped>
.slider-text {
  text-align: center;
  font-size: 1.125rem;
  text-transform: uppercase;
}

::v-deep .v-input {
  align-items: center;
}

::v-deep .v-input__slider {
  width: 20rem;

  .v-slider__track-container {
    height: 0.25rem;
  }

  .v-input__prepend-outer, .v-input__append-outer {
    .v-btn, .v-icon {
      color: $secondary !important;
    }

    .v-btn--disabled {
      opacity: 0.6;
    }
  }

  @media screen and (max-width: 48rem) {
    width: 16rem;
  }
}
</style>
