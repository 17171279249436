import filter from 'lodash/filter';
import find from 'lodash/find';
import some from 'lodash/some';
import sumBy from 'lodash/sumBy';

export const fullWeeks = ({ items }) => filter(items, it => !it.isWeekend);
export const weekends = ({ items }) => filter(items, it => it.isWeekend);

export const isAccommodationAvailable = (_, getters, { bookings }) => type => {
  const { availableSlotsPerWeek, fullWeeks, weekends } = getters;
  const weeks = bookings.filter.weekType === 'WEEK' ? fullWeeks : weekends;
  return some(weeks, week => {
    const { slots, rooms } = week.villa;
    const availableSlots = availableSlotsPerWeek({ slots, rooms, week });
    if (type === 'SHARED') return week.isShared && !!availableSlots;
    return availableSlots === slots;
  });
};

export const privateWeekVillas = (_state, getters) => {
  const { availableSlotsPerWeek, fullWeeks } = getters;
  return filter(fullWeeks, ({ id, villa }) => {
    const opts = { ...villa, week: { id } };
    return availableSlotsPerWeek(opts) === villa.slots;
  });
};

export const privateWeekendVillas = (_state, getters) => {
  const { availableSlotsPerWeek, weekends } = getters;
  return filter(weekends, ({ id, villa }) => {
    const opts = { ...villa, week: { id } };
    return availableSlotsPerWeek(opts) === villa.slots;
  });
};

export const availableSlotsPerWeek = () => ({ slots, rooms, week }) => {
  if (!rooms.length) return 0;
  const booked = filter(rooms, it => find(it.bookings, { weekId: week.id }));
  return slots - sumBy(booked, 'slots');
};
