<template>
  <v-app-bar elevate-on-scroll fixed app>
    <a href="https://villaweek.com" class="logo-container">
      <v-icon size="36" class="logo">$VWLogo</v-icon>
    </a>
    <v-sheet class="navbar-content">
      <v-slide-group
        v-model="currentStep"
        mandatory center-active show-arrows dark>
        <v-slide-item
          v-for="{ name, svg, icon, enabled = true } in steps"
          v-slot="{ active }"
          :key="name"
          :value="name">
          <v-btn
            :to="{ name }"
            :ripple="false"
            :disabled="!enabled"
            :class="{ 'active-button-content': active }"
            icon exact>
            <v-icon :size="svg ? 20 : 24">
              {{ icon ? `mdi-${icon}` : `$${svg}` }}
            </v-icon>
          </v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
    <user-menu />
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex';
import UserMenu from './UserMenu';

export default {
  name: 'navbar-mobile-view',
  props: {
    steps: { type: Array, required: true }
  },
  data: () => ({ currentStep: null }),
  computed: mapState('auth', ['user']),
  watch: {
    $route: {
      immediate: true,
      handler({ name }) {
        this.currentStep = name;
      }
    }
  },
  components: { UserMenu }
};
</script>

<style lang="scss" scoped>
.v-btn, .v-btn .v-icon {
  color: $white;
}

::v-deep {
  .v-slide-group__content {
    align-items: center;
    justify-content: space-evenly;
  }
}

.v-btn.v-btn--disabled {
  opacity: 0.65;

  .v-icon {
    color: $white !important;
  }
}

.v-btn::before {
  content: none;
}

.navbar-content {
  width: calc(100% - 6.75rem);

  .active-button-content {
    .v-icon {
      color: $primary;
    }

    .v-icon ::v-deep svg {
      fill: $primary;
    }
  }
}
</style>
