<template>
  <div class="accommodation-container">
    <div class="header">
      <h1 class="text-title">Accommodation</h1>
      <h2 class="text-subtitle">
        Choose type that suits you or your group.
        Multiselection is allowed.
      </h2>
      <p class="text-regular total-guests">Total guests: {{ guests }}</p>
    </div>
    <div class="accommodations">
      <accommodation-card
        v-for="unit in sortBy(bookableRooms, 'isBooked')"
        :key="unit.id"
        v-bind="{ unit, week }" />
    </div>
    <accommodation-footer :rooms="rooms" />
    <vw-snackbar v-model="snackbar" v-bind="snackbarOptions" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import AccommodationCard from './Card';
import AccommodationFooter from './Footer';
import get from 'lodash/get';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

const snackbarOptions = vm => ({
  message: `We noticed you are coming alone. Since the smallest Villa Week
  capacity is a 2-bed room we encourage you to bring someone with you on this
  amazing journey. You can still proceed to book the whole bedroom to yourself.`,
  color: 'danger',
  timeout: 30000,
  action: () => vm.setOneGuestWarning({ seen: true })
});

export default {
  name: 'accommodations',
  data: () => ({ snackbar: false }),
  computed: {
    ...mapState('bookings', ['filter', 'rooms', 'oneGuestWarningSeen']),
    ...mapGetters('bookings', ['guests', 'price', 'units']),
    snackbarOptions,
    bookableRooms() {
      const rooms = get(this, 'filter.villa.rooms', []);
      return map(rooms, room => ({
        ...room,
        type: 'BEDROOM',
        isSelected: this.rooms.some(({ id }) => id === room.id),
        isBooked: room.bookings.some(({ weekId }) => weekId === this.week.id)
      }));
    },
    week: vm => vm.filter.week || {}
  },
  methods: {
    ...mapMutations('bookings', ['validateStep', 'setOneGuestWarning']),
    sortBy
  },
  mounted() {
    const { guests, oneGuestWarningSeen } = this;
    if (guests === 1 && !oneGuestWarningSeen) this.snackbar = true;
  },
  components: { AccommodationCard, AccommodationFooter }
};
</script>

<style lang="scss" scoped>
@import '@/main/stylesheets/vuetify';

.accommodation-container {
  display: flex;
  flex-direction: column;

  .accommodations {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 1rem 0;
  }

  .text-regular.total-guests {
    margin: 1rem 0 0.25rem 0;
    text-align: center;
    color: $light-blue-grey;
    font-size: 1.125rem;
    font-weight: 600;
    letter-spacing: 0;
    text-transform: uppercase;
  }
}
</style>
