<template>
  <v-carousel
    v-model="activeSlide"
    :show-arrows="assets.length > 1"
    :height="assets.length ? 260 : 130"
    prev-icon="mdi-chevron-left"
    next-icon="mdi-chevron-right"
    hide-delimiters>
    <v-carousel-item
      v-for="({ url }, i) in assets"
      :key="i"
      reverse-transition="fade"
      transition="fade"
      eager>
      <carousel-asset v-if="activeSlide === i" :url="url" />
    </v-carousel-item>
    <v-icon v-if="!isDialogExperience" @click="$emit('close')" class="unexpand">
      mdi-chevron-up
    </v-icon>
    <div :class="{ 'no-assets': !assets.length }" class="info-container">
      <div class="mt-2">
        <v-chip
          v-if="!isDialogExperience"
          x-small
          class="readonly">
          <p class="chip-text text-uppercase">{{ category.name }}</p>
        </v-chip>
      </div>
      <div class="d-flex justify-space-between align-start">
        <div class="ml-2">
          <p v-if="subtitle" class="text-regular text-left white--text mb-1">
            {{ subtitle }}
          </p>
          <p class="text-title">{{ name }}</p>
        </div>
        <v-icon
          v-if="description && !isDialogExperience"
          @click="showDescription = true"
          color="secondary"
          class="mr-2">
          mdi-information-outline
        </v-icon>
      </div>
    </div>
    <vw-dialog v-model="showDescription" :title="name">
      <template #content>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="included" v-html="description"></div>
      </template>
    </vw-dialog>
  </v-carousel>
</template>

<script>
import CarouselAsset from '../CarouselAsset';

export default {
  name: 'included-experience-carousel',
  inheritAttrs: false,
  props: {
    name: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    description: { type: String, default: '' },
    category: { type: Object, required: true },
    assets: { type: Array, default: () => [] },
    isDialogExperience: { type: Boolean, default: false }
  },
  data: () => ({ showDescription: false, activeSlide: 0 }),
  components: { CarouselAsset }
};
</script>
