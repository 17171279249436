<template>
  <div class="thank-you-container">
    <h1 class="text-title">Thank you!</h1>
    <p class="text-subtitle">
      Your booking has been saved and you should receive an email with your
      booking confirmation.
      <br>
      Someone will be in touch shortly to confirm all details and to welcome you
      to Villa Week.
      <br>
      We're looking forward to seeing you soon!
    </p>
    <v-btn
      :to="{ path: '/' }"
      color="cyan"
      depressed rounded
      class="white--text">
      Book again
    </v-btn>
  </div>
</template>

<script>
export default {
  methods: {
    reload: vm => location.reload()
  }
};
</script>

<style lang="scss" scoped>
.thank-you-container {
  padding: 3rem 2.5rem;
  text-align: center;

  .v-btn {
    width: 60%;
    margin-top: 1rem;
  }
}
</style>
