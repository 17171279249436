import '@/common/validation';
import { formatDate, truncate } from '@/common/filters';
import {
  setInteractionMode,
  ValidationObserver,
  ValidationProvider
} from 'vee-validate';
import App from './App';
import getStore from './store';
import router from './router';
import Vue from 'vue';
import vuetify from './plugins/vuetify';
import VWCard from './components/common/VWCard';
import VWDialog from './components/common/VWDialog';
import VWSnackbar from './components/common/VWSnackbar';

Vue.component(VWDialog.name, VWDialog);
Vue.component(VWCard.name, VWCard);
Vue.component(VWSnackbar.name, VWSnackbar);
Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
setInteractionMode('eager');

Vue.filter('formatDate', formatDate);
Vue.filter('truncate', truncate);

Vue.config.productionTip = false;

// eslint-disable-next-line no-new
getStore().then(store => new Vue({
  store,
  router,
  vuetify,
  el: '#app',
  render: h => h(App)
}));
