var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"confirm-booking-container"},[_vm._m(0),_c('h2',{staticClass:"sub-header general-info"},[_vm._v("General Information")]),_c('general-info',_vm._b({},'general-info',_vm.filter,false)),_c('v-expansion-panels',{staticClass:"week-activities",attrs:{"tile":"","flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Amenities")]),_c('v-expansion-panel-content',{staticClass:"mx-1"},[(_vm.selectedAmenities.length)?_c('amenity-list',_vm._b({},'amenity-list',{ format: _vm.format, selectedAmenities: _vm.selectedAmenities },false)):_c('div',[_vm._v("No amenities picked or included in base package")])],1)],1),_c('v-expansion-panel',{staticClass:"mt-0"},[_c('v-expansion-panel-header',[_vm._v("Experiences")]),_c('v-expansion-panel-content',{staticClass:"mx-1"},[(_vm.selectedExperiences.length)?_c('experience-list',_vm._b({},'experience-list',{ format: _vm.format, selectedExperiences: _vm.selectedExperiences },false)):_c('div',[_vm._v("No experiences picked or included in base package")])],1)],1)],1),_c('h2',{staticClass:"sub-header"},[_vm._v("Manage Booking")]),_c('validation-observer',{ref:"form",attrs:{"slim":""}},[_c('div',{staticClass:"section-container"},_vm._l((_vm.formFields),function(ref,key){
var type = ref.type;
var options = ref.options;
var validation = ref.validation;
return _c('validation-provider',{key:key,attrs:{"name":options.label,"debounce":1000,"rules":validation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(type,_vm._b({tag:"component",class:{ required: validation.required },attrs:{"error-messages":errors},model:{value:(_vm.user[key]),callback:function ($$v) {_vm.$set(_vm.user, key, $$v)},expression:"user[key]"}},'component',options,false))]}}],null,true)})}),1),(!!_vm.locations.length)?_c('div',{staticClass:"section-container"},[_c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":"","align":"center"}},[_c('span',{staticClass:"text-label"},[_vm._v("Pick-up location")]),_c('v-icon',{staticClass:"info-icon",on:{"click":function($event){_vm.show.pickupModal = true}}},[_vm._v(" $info ")])],1),_c('validation-provider',{attrs:{"debounce":1000,"rules":{ required: true },"name":"Pick-up location"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.locations,"error-messages":errors,"placeholder":"Select"},model:{value:(_vm.pickup),callback:function ($$v) {_vm.pickup=$$v},expression:"pickup"}})]}}],null,false,2782327136)})],1):_vm._e(),_c('payment-options',_vm._b({model:{value:(_vm.payment),callback:function ($$v) {_vm.payment=$$v},expression:"payment"}},'payment-options',{ format: _vm.format, price: _vm.price, deposit: _vm.deposit },false)),_c('policy'),_c('div',{staticClass:"section-container"},[_c('terms',{model:{value:(_vm.terms),callback:function ($$v) {_vm.terms=$$v},expression:"terms"}}),_c('validation-provider',{attrs:{"debounce":1000,"rules":{ required: { allowFalse: false } },"name":"Travel insurance"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"error-messages":errors,"label":"I agree to have travel insurance for the duration of the event."},model:{value:(_vm.travelInsurance),callback:function ($$v) {_vm.travelInsurance=$$v},expression:"travelInsurance"}})]}}])}),_c('v-checkbox',{attrs:{"label":"Yes, please keep me updated on The Villa Week news, events and offers."},model:{value:(_vm.user.subscribed),callback:function ($$v) {_vm.$set(_vm.user, "subscribed", $$v)},expression:"user.subscribed"}})],1)],1),_c('div',{staticClass:"section-container"},[_c('actions',_vm._b({attrs:{"name":_vm.bookingName,"email":_vm.user.email,"total-amount":_vm.payment},on:{"validate":_vm.validateAll,"save":_vm.saveBooking}},'actions',{ format: _vm.format, isValid: _vm.isValid },false))],1),_c('vw-dialog',{attrs:{"title":"Pick-up location"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.pickupInfo)}})]},proxy:true}]),model:{value:(_vm.show.pickupModal),callback:function ($$v) {_vm.$set(_vm.show, "pickupModal", $$v)},expression:"show.pickupModal"}}),_c('vw-snackbar',_vm._b({model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},'vw-snackbar',_vm.snackbarOptions,false))],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('h1',{staticClass:"text-title"},[_vm._v("Confirm Booking")])])}]

export { render, staticRenderFns }