<template>
  <div class="amenity-list-container">
    <v-expansion-panels
      v-if="includedAmenities.length"
      :value="0"
      tile flat
      class="already-included">
      <v-expansion-panel>
        <v-expansion-panel-header class="mb-3">
          What's Already Included?
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <included-amenity-list />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <h3 v-if="includedAmenities.length" class="sub-header">
      Pick Additional Amenities
    </h3>
    <amenity-list
      v-if="optionalAmenities.length"
      :amenities="optionalAmenities" />
    <p v-else class="no-data">No Amenities to pick</p>
  </div>
</template>

<script>
import AmenityList from './List';
import IncludedAmenityList from '@/main/components/common/IncludedAmenityList';
import { mapGetters } from 'vuex';

export default {
  name: 'amenities',
  computed: mapGetters('bookings', ['includedAmenities', 'optionalAmenities']),
  components: { IncludedAmenityList, AmenityList }
};
</script>

<style lang="scss" scoped>
@import '@/main/stylesheets/vuetify';

::v-deep .v-expansion-panels {
  margin-bottom: 1.25rem;

  .v-expansion-panel-header {
    font-size: 1.5rem;

    .v-icon {
      font-size: 1.375rem;
    }
  }
}

.sub-header {
  padding-left: 0.25rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: start;
}

.no-data {
  padding: 1rem;
  color: $light-blue-grey;
  font-size: 1rem;
}

@media screen and (max-width: 56.25rem) {
  .sub-header {
    font-size: 1.25rem;
  }

  .no-data {
    font-size: 0.875rem;
  }
}
</style>
