<template>
  <vw-card
    @click="toggleRoom(unit)"
    :assets="unit.assets"
    :is-selected="unit.isSelected"
    :is-booked="unit.isBooked"
    :has-available-capacity="!unit.isBooked"
    class="card">
    <template #body>
      <v-chip
        v-show="unit.comfort"
        :color="comfortColor"
        :class="`readonly ${kebabCase(unit.comfort)}`"
        small>
        <p class="chip-text text-capitalize">{{ unit.comfort }}</p>
      </v-chip>
      <v-spacer />
      <div class="mb-4">
        <p class="text-title">{{ unit.type }}</p>
        <p class="text-regular mb-0">
          Max capacity
          <span class="font-weight-bold">{{ unit.slots }} PAX</span>
        </p>
        <p class="text-regular mb-0">
          Bathrooms
          <span class="font-weight-bold">{{ bathrooms || 0 }}</span>
        </p>
      </div>
    </template>
    <template #footer>
      <p class="text-regular">from {{ format(week.price) }} per person</p>
    </template>
  </vw-card>
</template>

<script>
import { COMFORT_COLORS } from '@/main/common/config';
import { currencyFormatter } from '@/main/common/utils';
import kebabCase from 'lodash/kebabCase';
import { mapMutations } from 'vuex';

export default {
  name: 'accomodation-card',
  props: {
    unit: { type: Object, required: true },
    week: { type: Object, required: true }
  },
  computed: {
    comfortColor: vm => COMFORT_COLORS[vm.unit.comfort],
    bathrooms: vm => vm.unit.bathrooms
  },
  methods: {
    kebabCase,
    format: currencyFormatter({ digits: 0 }).format,
    ...mapMutations('bookings', ['toggleRoom'])
  }
};
</script>

<style lang="scss" scoped>
.card {
  height: 21rem !important;

  .v-window .text-regular {
    color: $light-blue-grey;
  }
}

::v-deep .v-chip {
  .v-chip__content .chip-text {
    margin: 0;
    color: $white;
    font-weight: 600;
    letter-spacing: 0.0625rem;
  }

  &.economy .v-chip__content .chip-text {
    color: $black;
  }
}
</style>
