import Accommodation from './components/Accommodation';
import Amenities from './components/Amenities';
import Auth from '@/admin/components/auth';
import BookableWeek from './components/BookableWeek';
import Booking from './components';
import ConfirmBooking from './components/ConfirmBooking';
import CrewSelection from './components/CrewSelection';
import Experiences from './components/Experiences';
import isEmpty from 'lodash/isEmpty';
import Locations from './components/Locations';
import MagicLogin from './components/MagicLogin';
import NotFound from '@/common/components/NotFound';
import Packages from './components/Packages';
import RoomType from './components/RoomType';
import Router from 'vue-router';
import ThankYou from './components/ThankYou';
import Vue from 'vue';
import WeekType from './components/WeekType';

Vue.use(Router);

const scrollBehavior = () => ({ x: 0, y: 0 });

// Handle 404
const fallbackRoute = { path: '*', component: NotFound };

const routes = [{
  component: Auth,
  path: '/auth',
  name: 'auth',
  children: [{
    component: MagicLogin,
    path: 'magic-login',
    name: 'magic-login'
  }]
}, {
  component: Booking,
  path: '/',
  children: [{
    component: Locations,
    name: 'locations',
    path: ''
  }, {
    component: Packages,
    name: 'packages',
    path: 'packages',
    meta: { auth: true },
    beforeEnter: (_to, _from, next) => {
      if (!router.app.$store.getters['auth/isAdmin']) return next();
      return next({ name: 'locations' });
    }
  }, {
    component: WeekType,
    name: 'week-type',
    path: 'week-type'
  }, {
    component: RoomType,
    name: 'room-type',
    path: 'room-type'
  }, {
    component: BookableWeek,
    name: 'bookable-week',
    path: 'bookable-week'
  }, {
    component: CrewSelection,
    name: 'crew-selection',
    path: 'crew-selection'
  }, {
    component: Accommodation,
    name: 'accommodations',
    path: 'accommodations'
  }, {
    component: Amenities,
    name: 'amenities',
    path: 'amenities'
  }, {
    component: Experiences,
    name: 'experiences',
    path: 'experiences'
  }, {
    component: ConfirmBooking,
    name: 'confirm-booking',
    path: 'confirm-booking'
  }, {
    component: ThankYou,
    name: 'thank-you',
    path: 'thank-you'
  }]
}, fallbackRoute];

const router = new Router({
  mode: process.env.HISTORY_API_FALLBACK ? 'history' : 'hash',
  routes,
  scrollBehavior
});

router.beforeEach(async (to, _from, next) => {
  const { auth: { user }, bookings: { filter } } = router.app.$store.state;
  const isNotAuthenticated = to.matched.some(it => it.meta.auth) && !user;
  if (isNotAuthenticated) return next({ name: 'locations' });
  const isFirstStep = ['locations', 'packages', 'magic-login', 'thank-you'].includes(to.name);
  if (isFirstStep) return next();
  if (isEmpty(filter)) return next({ name: 'locations' });
  return next();
});

export default router;
