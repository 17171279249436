<template>
  <div>
    <v-alert
      v-if="!magicToken || invalidToken"
      color="pink lighten-1"
      transition="fade-transition"
      text dense>
      No valid credentails found.
    </v-alert>
    <div v-else class="d-flex justify-center">
      <v-progress-circular
        v-if="isLoading"
        color="primary darken-2"
        indeterminate />
      <div v-else>Credentials verified. Redirecting...</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'magic-login',
  data: () => ({
    isLoading: true,
    invalidToken: false
  }),
  computed: {
    magicToken: vm => vm.$route.query.token,
    redirect: vm => vm.$route.query.redirect
  },
  methods: {
    ...mapActions('auth', ['magicLogin']),
    verifyToken() {
      const { magicToken, redirect } = this;
      return this.magicLogin(magicToken)
        .then(() => this.$router.replace({ name: redirect } || '/'))
        .catch(() => (this.invalidToken = true))
        .finally(() => (this.isLoading = false));
    }
  },
  created() {
    if (this.magicToken) return this.verifyToken();
  }
};
</script>
