<template>
  <div class="section-container">
    <v-expansion-panels tile flat class="cancellation-policy">
      <v-expansion-panel>
        <v-expansion-panel-header>Cancellation Policy</v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="cancellationPolicy"></div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'policy',
  computed: mapState('settings', ['cancellationPolicy'])
};
</script>

<style lang="scss" scoped>
@import '@/main/stylesheets/vuetify';

.v-expansion-panel-content ::v-deep {
  h1, h2, h3 {
    margin: 0.8125rem 0 0.3125rem;
    color: #1f1d1d;
  }

  h1 {
    font-size: 1.125rem;
  }

  h2 {
    font-size: 1rem;
  }

  h3 {
    font-size: 0.9375rem;
  }

  p {
    font-size: 0.875rem;
  }

  @media screen and (max-width: 48rem) {
    h1 {
      font-size: 1rem;
    }

    h2 {
      font-size: 0.875rem;
    }

    h3 {
      font-size: 0.8125rem;
    }

    p {
      font-size: 0.75rem;
    }
  }
}
</style>
