var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"crew-selection-container"},[_vm._m(0),_c('div',{staticClass:"sliders"},_vm._l((_vm.sliders),function(ref){
var key = ref.key;
var label = ref.label;
var hint = ref.hint;
var retainSlots = ref.retainSlots;
var max = ref.max;
return _c('crew-slider',{key:key,attrs:{"value":_vm.crew[key],"label":label,"hint":hint,"max":max || _vm.villaSlots,"remaining":_vm.villaSlots - _vm.guests,"retain-slots":retainSlots},on:{"change":function($event){
var _obj;
return _vm.update(( _obj = {}, _obj[key] = $event, _obj ))}}})}),1),_c('p',{staticClass:"text-regular total-guests mb-2"},[_vm._v(" Selected: "),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.guests))])]),_c('p',{staticClass:"text-regular"},[_vm._v(_vm._s(_vm.capacityLabel))]),_c('proceed-footer',{attrs:{"disabled":!_vm.adults},on:{"proceed":_vm.proceed}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('h1',{staticClass:"text-title"},[_vm._v("Your Group")]),_c('h2',{staticClass:"text-subtitle"},[_vm._v(" Select the number of people in your group to see all available accommodation types at a price per person. ")])])}]

export { render, staticRenderFns }