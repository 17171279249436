<template>
  <div>
    <v-btn @click="createCharge" color="#0069c2" depressed class="coinbase">
      <span>Coinbase</span> Checkout
    </v-btn>
    <vw-dialog v-model="showModal" title="Crypto payment">
      <template #content>
        <p class="text-subtitle">Your booking is reserved!</p>
        <p class="text-subtitle">Checking for payment to confirm the booking</p>
        <p class="text-subtitle font-weight-bold">{{ expirationTime }}</p>
      </template>
      <template #footer>
        <div class="actions">
          <v-progress-circular
            v-if="!expirationTime"
            color="primary"
            size="50"
            indeterminate />
          <template v-else>
            <v-btn
              @click="$emit('paid', charge)"
              :href="chargeId ? charge.hosted_url : ''"
              rel="noopener noreferrer"
              target="_blank"
              color="#0069c2"
              class="white--text">
              COINBASE
              <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
            <div class="text-regular mt-2">Proceed to Coinbase payment page</div>
            <v-btn @click="showModal = false" color="danger" outlined class="mt-4">
              CANCEL
            </v-btn>
            <div class="text-regular mt-2">Change payment provider</div>
          </template>
        </div>
      </template>
    </vw-dialog>
  </div>
</template>

<script>
import coinbase from 'coinbase-commerce-node';
import get from 'lodash/get';
import intervalToDuration from 'date-fns/intervalToDuration';

const Charge = coinbase.resources.Charge;

const { COINBASE_API_KEY } = process.env;

const resetData = () => ({
  charge: null,
  chargeId: null,
  timerId: null,
  expirationTime: ''
});

export default {
  name: 'coin-base',
  props: {
    amount: { type: Number, required: true }
  },
  data: () => ({
    showModal: false,
    ...resetData()
  }),
  computed: {
    expiresAt: vm => get(vm.charge, 'expires_at')
  },
  methods: {
    clearData() {
      clearInterval(this.timerId);
      Object.assign(this, resetData());
    },
    async createCharge() {
      this.showModal = true;
      coinbase.Client.init(COINBASE_API_KEY);
      const chargeData = {
        name: 'The Villa Week',
        local_price: {
          amount: this.amount,
          currency: 'EUR'
        },
        pricing_type: 'fixed_price'
      };
      const charge = await Charge.create(chargeData);
      this.chargeId = charge.id;
      this.charge = charge;
      this.countdown();
    },
    countdown() {
      const { chargeId, expiresAt } = this;
      this.timerId = setInterval(() => {
        if (!chargeId) return;
        const { minutes, seconds } = intervalToDuration({
          start: new Date(expiresAt),
          end: new Date()
        });
        this.expirationTime = `${minutes}:${seconds}`;
      }, 1000);
    }
  },
  watch: {
    showModal(val) {
      if (!val) this.clearData();
    }
  }
};
</script>

<style lang="scss" scoped>
.text-subtitle {
  font-size: 1rem;
  text-align: center;
}

.text-regular {
  color: $light-grey;
}

.coinbase {
  width: 15.5rem;
  height: 2.5rem;
  font-size: 0.6875rem;
  font-weight: 600;
  letter-spacing: 0;
  border: none;

  span {
    margin-right: 0.3125rem;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1rem;

  .v-btn {
    min-width: 10rem;
    color: white;
    font-size: 1.125rem;

    @media screen and (max-width: 48rem) {
      min-width: 8rem;
      font-size: 1rem;
    }
  }

  .v-icon {
    margin-left: 0.25rem;
    font-size: 1rem;
  }
}
</style>
