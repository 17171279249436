<template>
  <div class="packages-container">
    <div v-if="isLoading" class="progress-container">
      <v-progress-circular size="60" color="#cfd8dc" indeterminate />
    </div>
    <template v-else>
      <div class="header">
        <h1 class="text-title">Packages</h1>
        <h2 class="text-subtitle">
          Custom packages made just for you. Choose the Amenities & Experiences
          that suit you.
        </h2>
      </div>
      <div class="packages">
        <package-card
          v-for="pack in packages"
          :key="pack.id"
          :outside-package="pack" />
      </div>
    </template>
    <proceed-footer
      v-if="!!filter.outsidePackage"
      @proceed="$router.push({ name: 'amenities' })" />
  </div>
</template>

<script>
import api from '@/admin/api/outsidePackage';
import { mapState } from 'vuex';
import orderBy from 'lodash/orderBy';
import PackageCard from './Card';
import ProceedFooter from '@/main/components/common/ProceedFooter';

export default {
  name: 'packages-list',
  data: () => ({ packages: [], isLoading: false }),
  computed: mapState('bookings', ['filter']),
  async created() {
    const packages = await api.fetch();
    this.packages = orderBy(packages, 'booking', 'desc');
  },
  components: { PackageCard, ProceedFooter }
};
</script>

<style lang="scss" scoped>
.packages-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .packages {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 1rem -1rem;
  }
}
</style>
