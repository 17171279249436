var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"elevate-on-scroll":"","fixed":"","app":""}},[_c('a',{staticClass:"logo-container",attrs:{"href":"https://villaweek.com"}},[_c('v-icon',{staticClass:"logo",attrs:{"size":"36"}},[_vm._v("$VWLogo")])],1),_c('v-sheet',{staticClass:"navbar-content"},[_c('v-slide-group',{attrs:{"mandatory":"","center-active":"","show-arrows":"","dark":""},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},_vm._l((_vm.steps),function(ref){
var name = ref.name;
var svg = ref.svg;
var icon = ref.icon;
var enabled = ref.enabled; if ( enabled === void 0 ) enabled = true;
return _c('v-slide-item',{key:name,attrs:{"value":name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-btn',{class:{ 'active-button-content': active },attrs:{"to":{ name: name },"ripple":false,"disabled":!enabled,"icon":"","exact":""}},[_c('v-icon',{attrs:{"size":svg ? 20 : 24}},[_vm._v(" "+_vm._s(icon ? ("mdi-" + icon) : ("$" + svg))+" ")])],1)]}}],null,true)})}),1)],1),_c('user-menu')],1)}
var staticRenderFns = []

export { render, staticRenderFns }