<template>
  <div class="experience-carousel">
    <v-carousel
      v-model="activeSlide"
      :show-arrows="assets.length > 1"
      :height="assets.length ? 260 : 130"
      prev-icon="mdi-chevron-left"
      next-icon="mdi-chevron-right"
      hide-delimiters>
      <v-carousel-item
        v-for="({ url }, i) in assets"
        :key="i"
        reverse-transition="fade"
        transition="fade"
        eager>
        <carousel-asset v-if="activeSlide === i" :url="url" />
      </v-carousel-item>
      <v-icon @click="$emit('contract')" class="unexpand">
        mdi-chevron-up
      </v-icon>
      <div :class="{ 'no-assets': !assets.length }" class="info-container">
        <div class="mt-2">
          <v-chip x-small class="readonly">
            <p class="chip-text text-uppercase">{{ category.name }}</p>
          </v-chip>
        </div>
        <div class="exp-title d-flex justify-space-between align-start">
          <div class="ml-2">
            <p class="text-regular text-left mb-1">{{ subtitle }}</p>
            <p :class="{ 'mb-2': limitedCapacity }" class="text-title">
              {{ name }}
            </p>
            <div class="additional-info">
              <span v-if="limitedCapacity" class="text-regular danger--text mr-3">
                LIMITED CAPACITY
              </span>
            </div>
          </div>
          <v-icon
            v-if="description"
            @click="showDescription = true"
            color="secondary"
            class="mr-2 ml-3">
            mdi-information-outline
          </v-icon>
        </div>
      </div>
    </v-carousel>
    <vw-dialog v-model="showDescription" :title="name">
      <template #content>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="included" v-html="description"></div>
      </template>
    </vw-dialog>
    <v-divider v-if="!assets.length" class="my-0 mx-3" />
  </div>
</template>

<script>
import CarouselAsset from '@/main/components/common/CarouselAsset';

export default {
  name: 'amenity-carousel',
  inheritAttrs: false,
  props: {
    name: { type: String, required: true },
    subtitle: { type: String, default: '' },
    description: { type: String, default: '' },
    category: { type: Object, required: true },
    limitedCapacity: { type: Boolean, default: false },
    assets: { type: Array, default: () => [] }
  },
  data: () => ({ showDescription: false, activeSlide: 0 }),
  components: { CarouselAsset }
};
</script>
