<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div class="terms-content" v-html="termsAndConditions"></div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'terms-content',
  computed: mapState('settings', ['termsAndConditions'])
};
</script>

<style lang="scss" scoped>
.terms-content ::v-deep {
  h1, h2, h3 {
    margin: 0.8125rem 0 0.3125rem;
  }

  h1 {
    font-size: 1.125rem;
  }

  h2 {
    font-size: 1rem;
  }

  h3 {
    font-size: 0.9375rem;
  }

  p {
    font-size: 0.875rem;
  }

  @media screen and (max-width: 48rem) {
    h1 {
      font-size: 1rem;
    }

    h2 {
      font-size: 0.875rem;
    }

    h3 {
      font-size: 0.8125rem;
    }

    p {
      font-size: 0.75rem;
    }
  }
}
</style>
