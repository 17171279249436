<template>
  <div class="confirm-booking-container">
    <div class="header">
      <h1 class="text-title">Confirm Booking</h1>
    </div>
    <h2 class="sub-header general-info">General Information</h2>
    <general-info v-bind="filter" />
    <v-expansion-panels tile flat class="week-activities">
      <v-expansion-panel>
        <v-expansion-panel-header>Amenities</v-expansion-panel-header>
        <v-expansion-panel-content class="mx-1">
          <amenity-list
            v-if="selectedAmenities.length"
            v-bind="{ format, selectedAmenities }" />
          <div v-else>No amenities picked or included in base package</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mt-0">
        <v-expansion-panel-header>Experiences</v-expansion-panel-header>
        <v-expansion-panel-content class="mx-1">
          <experience-list
            v-if="selectedExperiences.length"
            v-bind="{ format, selectedExperiences }" />
          <div v-else>No experiences picked or included in base package</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <h2 class="sub-header">Manage Booking</h2>
    <validation-observer ref="form" slim>
      <div class="section-container">
        <validation-provider
          v-for="({ type, options, validation }, key) in formFields"
          :key="key"
          v-slot="{ errors }"
          :name="options.label"
          :debounce="1000"
          :rules="validation">
          <component
            :is="type"
            v-model="user[key]"
            v-bind="options"
            :error-messages="errors"
            :class="{ required: validation.required }" />
        </validation-provider>
      </div>
      <div v-if="!!locations.length" class="section-container">
        <v-row no-gutters align="center" class="mb-2">
          <span class="text-label">Pick-up location</span>
          <v-icon @click="show.pickupModal = true" class="info-icon">
            $info
          </v-icon>
        </v-row>
        <validation-provider
          v-slot="{ errors }"
          :debounce="1000"
          :rules="{ required: true }"
          name="Pick-up location">
          <v-select
            v-model="pickup"
            :items="locations"
            :error-messages="errors"
            placeholder="Select" />
        </validation-provider>
      </div>
      <payment-options v-model="payment" v-bind="{ format, price, deposit }" />
      <policy />
      <div class="section-container">
        <terms v-model="terms" />
        <validation-provider
          v-slot="{ errors }"
          :debounce="1000"
          :rules="{ required: { allowFalse: false } }"
          name="Travel insurance">
          <v-checkbox
            v-model="travelInsurance"
            :error-messages="errors"
            label="I agree to have travel insurance for the duration of the event." />
        </validation-provider>
        <v-checkbox
          v-model="user.subscribed"
          label="Yes, please keep me updated on The Villa Week news, events and offers." />
      </div>
    </validation-observer>
    <div class="section-container">
      <actions
        @validate="validateAll"
        @save="saveBooking"
        :name="bookingName"
        :email="user.email"
        :total-amount="payment"
        v-bind="{ format, isValid }" />
    </div>
    <vw-dialog v-model="show.pickupModal" title="Pick-up location">
      <template #content>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="pickupInfo"></div>
      </template>
    </vw-dialog>
    <vw-snackbar v-model="snackbar" v-bind="snackbarOptions" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Actions from './Actions';
import AmenityList from '@/main/components/common/IncludedAmenityList';
import { currencyFormatter } from '@/main/common/utils';
import ExperienceList from '@/main/components/common/IncludedExperienceList';
import { formFields } from './form';
import GeneralInfo from './General';
import PaymentOptions from './PaymentOptions';
import pick from 'lodash/pick';
import Policy from './Policy';
import RadioGroup from './common/RadioGroup';
import round from 'lodash/round';
import Terms from './Terms';

const snackbarOptions = () => ({
  message: 'Unable to submit form. Please fill the fields correctly.',
  color: 'danger',
  timeout: 5000,
  top: true
});

const setUserInfo = () => ({
  firstName: '',
  lastName: '',
  email: '',
  country: '',
  phoneNumber: '',
  gender: null,
  guests: '',
  subscribed: true
});

export default {
  name: 'confirm-booking',
  data: () => ({
    show: { pickupModal: false },
    user: setUserInfo(),
    pickup: '',
    payment: 0,
    terms: false,
    travelInsurance: false,
    showWeekActivities: false,
    isValid: false,
    hasError: false,
    snackbar: false
  }),
  computed: {
    ...mapState('auth', { me: 'user' }),
    ...mapGetters('bookings', ['price', 'guests', 'accommodationPrice']),
    ...mapState('settings', ['pickupInfo']),
    ...mapState('bookings', ['filter', 'rooms', 'experiences', 'amenities']),
    ...mapGetters('bookings', [
      'bathrooms',
      'getActivityPrice',
      'includedExperiences',
      'includedAmenities'
    ]),
    snackbarOptions,
    locations: vm => vm.filter.villa?.pickupLocations || [],
    bookingName() {
      const { outsidePackage, week } = this.filter;
      if (outsidePackage) return outsidePackage.name;
      return week.name;
    },
    name: ({ user }) => `${user.firstName} ${user.lastName}`,
    selectedExperiences: vm => [...vm.experiences, ...vm.includedExperiences],
    selectedAmenities: vm => [...vm.amenities, ...vm.includedAmenities],
    deposit() {
      const { outsidePackage, week } = this.filter;
      return outsidePackage?.deposit || week?.deposit || this.guests * 500;
    },
    weekActivities() {
      const weekActivities = [...this.experiences, ...this.amenities];
      return weekActivities.map(it => {
        const pricePerPerson = round(this.getActivityPrice(it), 2);
        return { id: it.id, pricePerPerson, slots: it.selectedSlots };
      });
    },
    formFields: vm => formFields(vm.filter.outsidePackage)
  },
  methods: {
    ...mapMutations('bookings', ['validateStep', 'setDefaultsForStep']),
    ...mapActions('bookings', ['save']),
    format: currencyFormatter().format,
    async saveBooking(processedPayment = 0) {
      const data = this.adjustData(processedPayment);
      await this.save(data);
      if (processedPayment) {
        this.$router.push({ name: 'thank-you' });
        this.setDefaultsForStep({ step: 0, validateNext: false });
      }
    },
    adjustData(processedPayment) {
      const { weekActivities, rooms, bathrooms, price, pickup, payment } = this;
      const crew = pick(this.filter, ['ladies', 'gentlemen', 'children', 'infants']);
      const { outsidePackage, villa, week, roomType, weekType } = this.filter;
      const slots = crew.ladies + crew.gentlemen + crew.children;
      const basicPricePerPerson = round(this.accommodationPrice / slots, 2);
      const { guests, ...user } = this.user;
      return {
        ...crew,
        pickup,
        user,
        roomType,
        weekType,
        guests,
        bathrooms: week && bathrooms,
        basicPricePerPerson,
        fullPrice: round(price, 2),
        payment: processedPayment,
        amountType: payment === price ? 'Full' : 'Deposit',
        amountPaid: round(payment, 2),
        villaId: villa?.id,
        weekId: week?.id,
        roomIds: rooms?.map(r => r.id),
        experiences: weekActivities,
        packageId: outsidePackage?.id
      };
    },
    async validateAll() {
      const { form } = this.$refs;
      this.isValid = await form.validate();
      if (!this.isValid) this.snackbar = true;
    }
  },
  mounted() {
    this.payment = Math.min(this.price, this.deposit);
    Object.assign(this.user, this.me);
    this.validateStep({ step: 8 });
  },
  components: {
    Actions,
    AmenityList,
    PaymentOptions,
    ExperienceList,
    Terms,
    RadioGroup,
    GeneralInfo,
    Policy
  }
};
</script>

<style lang="scss" scoped>
@import '@/main/stylesheets/vuetify';

.confirm-booking-container {
  max-width: 62.5rem;
  min-height: 100vh;
  padding: 0 6rem;

  ::v-deep .sub-header {
    letter-spacing: 0.0625rem;
    margin: 0 0.5rem;
    font-size: 1.375rem;
    font-weight: 600;

    &.general-info {
      margin-top: 1rem !important;
      margin-bottom: 0;
    }
  }

  ::v-deep .section-container {
    padding: 0.5rem 2rem 0.5rem;

    .v-input--selection-controls {
      margin: 0;
    }
  }
}

.required ::v-deep label::after {
  content: " *";
  color: $primary;
}

::v-deep .info-icon {
  width: 1rem;
  height: 1rem;
  margin-left: 0.25rem;
  fill: $secondary;
  outline: none;
  cursor: pointer;
}

.week-activities .v-expansion-panel-header {
  margin: 0 0.25rem 0.875rem;
  font-size: 1.375rem;

  .v-icon {
    font-size: 1.25rem;
  }
}

.week-activities .v-expansion-panel--active {
  margin-bottom: 1rem;
}

@media screen and (max-width: 48rem) {
  .confirm-booking-container {
    padding: 0.25rem;

    ::v-deep .sub-header {
      font-size: 1.25rem;
    }

    ::v-deep .section-container {
      padding: 0.75rem 0.125rem 0;
    }
  }
}
</style>
