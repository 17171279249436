<template>
  <div class="section-container">
    <div class="payment-options">
      <v-row no-gutters align="center">
        <span class="text-label">Payment options</span>
        <v-icon @click="show.paymentOptionsModal = true" class="info-icon">
          $info
        </v-icon>
      </v-row>
      <v-radio-group v-model="context">
        <v-radio :value="price">
          <template #label>
            Pay the full amount now
            <span class="price">{{ format(price) }}</span>
          </template>
        </v-radio>
        <v-radio :value="deposit" :disabled="deposit >= price">
          <template #label>
            Pay deposit now
            <span class="price">{{ format(deposit) }}</span>
          </template>
        </v-radio>
      </v-radio-group>
    </div>
    <div class="refund-info">
      <v-icon @click="show.refundModal = true" class="info-icon">
        $info
      </v-icon>
      <span>Fully refundable for 48 hours.</span>
    </div>
    <p class="no-questions">No questions asked. </p>
    <vw-dialog v-model="show.paymentOptionsModal" title="Payment options">
      <template #content>
        <payment-info />
      </template>
    </vw-dialog>
    <vw-dialog v-model="show.refundModal" title="Refund info">
      <template #content>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="refundInfo"></div>
      </template>
    </vw-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PaymentInfo from '@/main/components/common/PaymentInfo';

export default {
  name: 'payment-options',
  props: {
    format: { type: Function, required: true },
    price: { type: Number, required: true },
    deposit: { type: Number, required: true },
    value: { type: Number, required: true }
  },
  data: () => ({
    show: { paymentOptionsModal: false, refundModal: false }
  }),
  computed: {
    ...mapState('settings', ['refundInfo']),
    context: {
      get: vm => vm.value,
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  components: { PaymentInfo }
};
</script>

<style lang="scss" scoped>
::v-deep .payment-info h2 {
  font-weight: 600;
}

::v-deep .v-radio {
  .v-label {
    display: flex;
    justify-content: space-between;
  }

  .price {
    font-size: 1.125rem;
    letter-spacing: 0.0625rem;
    font-weight: 600;
  }
}

.refund-info {
  display: flex;
  justify-content: center;
  margin: 1.75rem 0 0.25rem;
  font-size: 1.125rem;
  font-weight: 600;

  .info-icon {
    margin: 0.125rem 0.375rem 0 0;
  }
}

.no-questions {
  font-size: 1.125rem;
  font-weight: 600;
  text-align: center;
}

@media screen and (max-width: 48rem) {
  .refund-info, .no-questions, .price {
    font-size: 1rem;
  }
}
</style>
