<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="102.506mm"
    height="120.424mm"
    version="1.1"
    style="shape-rendering: geometricPrecision; text-rendering: geometricPrecision; image-rendering: optimizeQuality; fill-rule: evenodd; clip-rule: evenodd;"
    viewBox="0 0 4738.63 5566.94"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <linearGradient
        id="id0"
        gradientUnits="userSpaceOnUse"
        x1="2369.32"
        y1="4028.79"
        x2="2369.32"
        y2="0">
        <stop offset="0" style="stop-opacity: 1; stop-color: #e5d600;"></stop>
        <stop offset="1" style="stop-opacity: 1; stop-color: #ec6820;"></stop>
      </linearGradient>
    </defs>
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"></metadata>
      <path class="fil0" d="M2501.32 3800.11l0.04 0 -107.05 185.36 -25.02 43.32 -129.17 -223.83 -387.69 -671.45 -279.83 484.74 -31.9 55.12 -53.62 92.92 -26.65 46.19c-655.87,-332.19 -1105.5,-1012.6 -1105.5,-1798.08 -0.02,-456.6 152.04,-877.63 408.11,-1215.5l60.72 105.28 61.99 107.35 885.18 1533.18 -129.24 223.8 -885.06 -1533.02c-113.94,235.5 -177.88,499.75 -177.88,778.91 -0.01,622.65 317.9,1170.91 800.15,1491.73l26.27 -45.59 219.46 -380.14 227.82 -394.53 148.3 -256.9 113.47 -196.53 44.37 -76.88 129.25 223.8 -306.19 530.32 387.65 671.46 237.92 -412.04 766.03 -1326.74 479.63 -830.84 71.06 -123.1 51.68 -89.53c256.12,337.81 408.11,758.9 408.11,1215.5 -0.03,785.48 -449.58,1465.87 -1105.49,1798.06l-51.7 -89.53 -340.3 -589.45 -384.92 666.64zm174.18 -1031.61l0 0 129.23 -223.8 -306.19 -530.32 923.05 -1598.8 0 -0.02 50.29 -87.13c-316.75,-207.6 -695.52,-328.43 -1102.57,-328.43 -407.04,0 -785.79,120.81 -1102.56,328.45l68.47 118.59 415.78 720.16 489.13 847.17 283.27 490.69 152.1 263.43zm490.59 -2357.97l0 0c-239.94,-119.46 -510.53,-186.73 -796.78,-186.72 -286.24,0 -556.82,67.23 -796.78,186.69l796.77 1380.09 796.79 -1380.06zm193.72 3095.57l0 0c482.22,-320.81 800.08,-869.09 800.08,-1491.71 -0.01,-279.17 -63.88,-543.43 -177.87,-778.92l-966.58 1674.21 344.36 596.43z">
      </path>
      <g id="_105553257227840">
        <polygon class="fil1" points="245.81,5566.94 337.88,5566.94 582.84,4971.41 470.5,4971.41 293.11,5425.88 114.89,4971.41 -0,4971.41 "></polygon>
        <polygon class="fil1" points="675.38,5562.72 760.57,5562.72 760.57,5077.89 675.38,5077.89 "></polygon>
        <polygon class="fil1" points="890.09,5562.72 1230.17,5562.72 1230.17,5485.15 975.29,5485.15 975.29,5077.89 890.09,5077.89 "></polygon>
        <polygon class="fil1" points="1318.82,5562.72 1658.9,5562.72 1658.9,5485.15 1404.03,5485.15 1404.03,5077.89 1318.82,5077.89 "></polygon>
        <path class="fil1" d="M1705.99 5562.72l87.28 0 49.87 -117.05 229.25 0 49.17 117.05 90.05 0 -213.34 -488.3 -78.94 0 -213.34 488.3zm168.32 -192.55l0 0 83.12 -193.93 83.8 193.93 -166.91 0z"></path>
        <polygon class="fil1" points="2611.87,5566.94 2701.4,5566.94 2846.7,5142.05 2992,5566.94 3082.39,5566.94 3287.64,4971.41 3178.68,4971.41 3037.6,5409.83 2892.31,4969.73 2804.48,4969.73 2659.18,5409.83 2518.11,4971.41 2405.76,4971.41 "></polygon>
        <polygon class="fil1" points="3380.39,5562.72 3743.32,5562.72 3743.32,5486.53 3465.59,5486.53 3465.59,5356.32 3708.7,5356.32 3708.7,5280.13 3465.59,5280.13 3465.59,5154.07 3739.88,5154.07 3739.88,5077.89 3380.39,5077.89 "></polygon>
        <polygon class="fil1" points="3844.46,5562.72 4207.38,5562.72 4207.38,5486.53 3929.65,5486.53 3929.65,5356.32 4172.77,5356.32 4172.77,5280.13 3929.65,5280.13 3929.65,5154.07 4203.93,5154.07 4203.93,5077.89 3844.46,5077.89 "></polygon>
        <polygon class="fil1" points="4308.52,5562.72 4393.7,5562.72 4393.7,5422.12 4469.19,5344.55 4634.74,5562.72 4738.63,5562.72 4527.39,5286.36 4729.64,5077.89 4624.35,5077.89 4393.7,5321.69 4393.7,5077.89 4308.52,5077.89 "></polygon>
      </g>
      <polygon class="fil1" points="1914.52,4755.59 1975.93,4755.59 1975.93,4465.37 2085.87,4465.37 2085.87,4408.91 1804.58,4408.91 1804.58,4465.37 1914.52,4465.37 "></polygon>
      <polygon class="fil1" points="2224.14,4755.59 2285.07,4755.59 2285.07,4609.5 2450.48,4609.5 2450.48,4755.59 2511.41,4755.59 2511.41,4408.91 2450.48,4408.91 2450.48,4553.03 2285.07,4553.03 2285.07,4408.91 2224.14,4408.91 "></polygon>
      <polygon class="fil1" points="2674.45,4755.59 2933.97,4755.59 2933.97,4701.11 2735.37,4701.11 2735.37,4608.01 2909.21,4608.01 2909.21,4553.53 2735.37,4553.53 2735.37,4463.39 2931.5,4463.39 2931.5,4408.91 2674.45,4408.91 "></polygon>
      <polygon class="fil1" points="-0,4597.92 1656.53,4597.92 1656.53,4566.58 -0,4566.58 "></polygon>
      <polygon class="fil1" points="3082.1,4597.92 4738.63,4597.92 4738.63,4566.58 3082.1,4566.58 "></polygon>
    </g>
  </svg>
</template>

<script>
export default { name: 'vw-logo-icon' };
</script>
