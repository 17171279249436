import filter from 'lodash/filter';
import partition from 'lodash/partition';
import Vue from 'vue';

const defaultState = {
  filter: () => {},
  rooms: () => [],
  amenities: () => [],
  experiences: () => []
};

const defaultPropsPerStep = [
  ['rooms', 'amenities', 'experiences', 'ladies', 'gentlemen', 'children', 'infants', 'pickup', 'roomType', 'villa', 'week', 'weekType', 'formattedDate'],
  ['rooms', 'amenities', 'experiences', 'ladies', 'gentlemen', 'children', 'infants', 'pickup', 'roomType', 'villa', 'week', 'formattedDate'],
  ['rooms', 'amenities', 'experiences', 'ladies', 'gentlemen', 'children', 'infants', 'pickup', 'villa', 'week', 'formattedDate'],
  ['rooms', 'amenities', 'experiences', 'ladies', 'gentlemen', 'children', 'infants', 'pickup'],
  ['rooms', 'amenities', 'experiences', 'pickup'],
  ['amenities', 'experiences', 'pickup'],
  ['experiences', 'pickup']
];

export const reset = state => {
  state.filter = {};
  state.rooms = [];
  state.amenities = [];
  state.experiences = [];
  state.weekFormattedExperiences = [];
  state.weekFormattedAmenities = [];
  state.oneGuestWarningSeen = false;
  state.stepValidation = new Array(9).fill(false);
};

export const updateFilter = (state, filter) => {
  state.filter = { ...state.filter, ...filter };
};

export const toggleRoom = (state, room) => {
  const { rooms } = state;
  const roomIndex = rooms.findIndex(r => r.id === room.id);
  if (roomIndex >= 0) rooms.splice(roomIndex, 1);
  else rooms.push(room);
};

export const setWeekRelatedExperiences = (state, relatedExps) => {
  const { experiences } = state.filter.week;
  state.filter.week.experiences = experiences.map(exp => {
    const related = filter(relatedExps, { parentExperienceId: exp.id });
    const separated = partition(related, 'isRecommendation');
    const recommendations = separated[0].map(it => it.childExperience);
    const childExperiences = separated[1].map(it => it.childExperience);
    return Object.assign(exp, { childExperiences, recommendations });
  });
};

export const setWeekFormattedExperiences = state => {
  const { filter: { week, outsidePackage, ladies, gentlemen, children } } = state;
  const guests = ladies + gentlemen + children;
  const { experiences } = week || outsidePackage;
  state.weekFormattedExperiences = experiences.map(exp => {
    return Object.assign({}, exp, manageSlots(exp, guests));
  });
};

export const updateWeekFormattedSlots = (state, { experience, slots }) => {
  // update exp slots
  const exp = state.weekFormattedExperiences.find(exp => exp.id === experience.id);
  exp.selectedSlots = slots;
};

export const upsertSelectedExperience = (state, { experience, slots }) => {
  // update exp slots
  const exp = state.experiences.find(exp => exp.id === experience.id);
  if (exp) return (exp.selectedSlots = slots);
  state.experiences.push({ ...experience, selectedSlots: slots });
};

export const removeSelectedExperience = (state, exp) => {
  const index = state.experiences.findIndex(({ id }) => id === exp.id);
  return Vue.delete(state.experiences, index);
};

export const resetExperiences = state => {
  state.experiences = [];
};

export const resetAmenities = state => {
  state.amenities = [];
};

export const setFullExperiencePackage = (state, availableOptionalExperiences) => {
  const fullSlotExperiences = availableOptionalExperiences.map(experience => {
    experience.selectedSlots = experience.initialSlots;
    return experience;
  });
  state.experiences.splice(0, state.experiences.length, ...fullSlotExperiences);
};

export const setWeekFormattedAmenities = state => {
  const { filter: { week, outsidePackage, ladies, gentlemen, children } } = state;
  const guests = ladies + gentlemen + children;
  const { amenities } = week || outsidePackage;
  state.weekFormattedAmenities = amenities.map(it => {
    return Object.assign({}, it, manageSlots(it, guests));
  });
};

export const updateWeekAmenityFormattedSlots = (state, { amenity, slots }) => {
  // update amenity slots
  const item = state.weekFormattedAmenities.find(({ id }) => id === amenity.id);
  item.selectedSlots = slots;
};

export const upsertSelectedAmenity = (state, { amenity, slots }) => {
  // update amenity slots
  const item = state.amenities.find(it => it.id === amenity.id);
  if (item) return (item.selectedSlots = slots);
  state.amenities.push({ ...amenity, selectedSlots: slots });
};

export const removeSelectedAmenity = (state, amenity) => {
  const index = state.amenities.findIndex(({ id }) => id === amenity.id);
  return Vue.delete(state.amenities, index);
};

export const setFullAmenityPackage = (state, availableOptionalAmenities) => {
  const fullSlotAmenities = availableOptionalAmenities.map(amenity => {
    amenity.selectedSlots = amenity.initialSlots;
    return amenity;
  });
  state.amenities.splice(0, state.amenities.length, ...fullSlotAmenities);
};

export const setFullVillaCapacity = (state, villa) => {
  state.rooms = villa.rooms;
};

export const setDefaultsForStep = (state, options) => {
  const { step, validateCurrent = true, validateNext = true } = options;
  const { stepValidation } = state;
  const nextStep = step + 1;
  const secondNextStep = step + 2;
  const stepCount = stepValidation.length - secondNextStep;
  const invalidatedSteps = new Array(stepCount).fill(false);
  defaultPropsPerStep[step].forEach(it => {
    const isRootState = !!state[it];
    const isFilterState = !!state.filter[it];
    if (isRootState) Vue.set(state, it, defaultState[it]());
    if (isFilterState) return Vue.delete(state.filter, it);
  });
  if (validateCurrent) state.stepValidation[step] = true;
  if (validateNext) state.stepValidation[nextStep] = true;
  state.stepValidation.splice(secondNextStep, stepCount, ...invalidatedSteps);
};

export const validateStep = (state, { step }) => {
  Vue.set(state.stepValidation, step, true);
};

export const invalidateStep = (state, { step }) => {
  Vue.set(state.stepValidation, step, false);
};

export const setOneGuestWarning = (state, { seen }) => {
  state.oneGuestWarningSeen = seen;
};

export {
  fetch,
  add,
  save,
  remove
} from '@/common/store/helpers/mutations';

function manageSlots({ slots, taken = 0 }, guests) {
  const unlimited = slots === null;
  const availableSlots = unlimited ? guests : slots - taken;
  const limitedCapacity = availableSlots < guests;
  const selectedSlots = limitedCapacity ? availableSlots : guests;
  const soldOut = !unlimited && !availableSlots;
  return {
    soldOut,
    limitedCapacity,
    availableSlots,
    selectedSlots,
    initialSlots: selectedSlots
  };
}
