<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="35.9497mm"
    height="48.6772mm"
    version="1.1"
    style="shape-rendering: geometricPrecision; text-rendering: geometricPrecision; image-rendering: optimizeQuality; fill-rule: evenodd; clip-rule: evenodd;"
    viewBox="0 0 27805.1 37649.11"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"></metadata>
      <path class="fil0" d="M2455.69 14155.97l5075.03 0 0 -2537.52c0,-3466.66 2836.3,-6302.95 6302.88,-6302.95l0.15 0c3466.66,0 6302.88,2836.3 6302.88,6302.95l0 2537.52 5075.11 0 0 -2777.98c0,-6257.94 -5120.05,-11377.99 -11377.99,-11377.99l-0.08 0c-6257.94,0 -11377.99,5120.05 -11377.99,11377.99l0 2777.98zm-908.8 1964.55l24711.32 0c850.79,0 1546.89,696.1 1546.89,1546.89l0 18434.82c0,850.79 -696.1,1546.89 -1546.89,1546.89l-24711.32 0c-850.79,0 -1546.89,-696.1 -1546.89,-1546.89l0 -18434.82c0,-850.79 696.1,-1546.89 1546.89,-1546.89zm10227.41 15634.56l4256.5 0 0 -3888.41c893.33,-655.49 1473.41,-1712.79 1473.41,-2905.68 0,-1989.14 -1612.55,-3601.7 -3601.7,-3601.7 -1989.14,0 -3601.7,1612.55 -3601.7,3601.7 0,1192.96 580.16,2250.26 1473.49,2905.68l0 3888.41z"></path>
    </g>
  </svg>
</template>

<script>
export default { name: 'lock-icon' };
</script>
