<template>
  <v-dialog
    v-on="$listeners"
    v-bind="$attrs"
    :width="width"
    overlay-opacity="0.97"
    no-click-animation persistent
    content-class="vw-dialog elevation-0 ma-4">
    <v-card color="transparent" tile flat>
      <v-card-title v-if="title" class="dialog-title pa-4">
        <h1 class="header-title">{{ title }}</h1>
        <v-btn @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="px-4 pt-4 pb-0">
        <slot name="content"></slot>
      </v-card-text>
      <v-card-actions class="px-4 pb-3">
        <slot name="footer"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'vw-dialog',
  props: {
    title: { type: String, default: '' },
    width: { type: [Number, String], default: 600 }
  },
  methods: {
    close() {
      this.$emit('input', false);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .vw-dialog {
  border-radius: 1.375rem;

  .v-card {
    width: 100%;
    height: 100%;
    margin: 0;

    ::v-deep .v-card__actions {
      justify-content: center;
    }
  }

  .dialog-title {
    display: flex;

    .header-title {
      font-size: 1.5rem;
      flex: 1;
      text-align: left;
      font-weight: 600;
      letter-spacing: 0.0625rem;
    }

    .v-icon {
      font-size: 1.25rem;
    }
  }

  .v-card__text h2 {
    font-size: 1rem;
    font-weight: 400;
  }
}
</style>
