<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="16.5101mm"
    height="16.2097mm"
    version="1.1"
    style="shape-rendering: geometricPrecision; text-rendering: geometricPrecision; image-rendering: optimizeQuality; fill-rule: evenodd; clip-rule: evenodd;"
    viewBox="0 0 298.68 293.24"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"></metadata>
      <path class="fil0" d="M97.53 166.04c-19.76,-5.04 -25.82,-14.82 -53.51,-24.41 -9.27,-3.21 -23.49,-7.16 -33.16,-3.53 -4.89,1.83 -8.33,5.33 -9.78,10.19 -2.63,8.81 -0.1,9.63 3.7,13.89 2.46,2.76 2.01,2.91 4.97,5.36 35.14,29.1 64.02,78.61 86.03,116.48 7.15,12.31 14.4,12.69 20.3,-1.13 48.94,-114.57 87.27,-169.15 177.16,-258.12 15.78,-15.62 -4.28,-39.68 -40.53,-12.31 -59.14,44.67 -97.17,88.17 -148.75,146.3 -1.22,1.37 -1.01,1.32 -2,2.74 -2.09,3.01 -1.37,5.33 -4.43,4.55z">
      </path>
    </g>
  </svg>
</template>

<script>
export default { name: 'checked-icon' };
</script>
