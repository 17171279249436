<template>
  <v-expansion-panel-header :hide-actions="soldOut || isChoosen">
    <div class="experience-header">
      <div class="d-flex flex-column">
        <div class="d-flex align-center justify-space-between">
          <div>
            <div class="name">{{ name | truncate(30) }}</div>
            <div v-if="hasAdditionalInfo" class="additional-info mt-2">
              <span
                v-if="limitedCapacity"
                class="text-regular danger--text mr-3">
                LIMITED CAPACITY
              </span>
              <span v-if="relatedExperiences.length" class="text-regular">
                +{{ relatedExperiences.length }} EXPERIENCE
              </span>
            </div>
          </div>
          <v-chip x-small class="readonly ml-2 mr-1">
            <p class="chip-text">{{ category.name }}</p>
          </v-chip>
        </div>
      </div>
      <span v-if="soldOut" class="text-regular sold-out">SOLD OUT</span>
      <span v-if="isChoosen" class="text-regular selected-slots">
        <span v-if="!group">{{ selectedSlots }}</span>
        <v-icon v-else>mdi-account-group</v-icon>
      </span>
    </div>
    <template #actions>
      <v-btn color="cyan" fab small outlined>
        <v-icon class="add-icon">mdi-plus</v-icon>
      </v-btn>
    </template>
  </v-expansion-panel-header>
</template>

<script>
export default {
  name: 'experience-panel-header',
  props: {
    id: { type: Number, required: true },
    name: { type: String, required: true },
    category: { type: Object, required: true },
    isChoosen: { type: Boolean, default: false },
    selectedSlots: { type: Number, default: null },
    limitedCapacity: { type: Boolean, default: false },
    group: { type: Boolean, default: false },
    soldOut: { type: Boolean, default: false },
    childExperiences: { type: Array, default: () => [] },
    recommendations: { type: Array, default: () => [] }
  },
  computed: {
    relatedExperiences: vm => vm.childExperiences.concat(vm.recommendations),
    hasAdditionalInfo: vm => vm.limitedCapacity || vm.relatedExperiences.length
  }
};
</script>

<style lang="scss" scoped>
.v-expansion-panel-header .experience-header {
  display: flex;
  align-items: center;
  width: calc(100% - 4rem);

  .d-flex {
    width: 100%;
  }

  .selected-slots, .sold-out {
    flex: 1;
    font-size: 0.625rem;
    text-align: end;
  }

  .sold-out {
    min-width: 4.25rem;
    color: $danger;
  }

  .selected-slots .v-icon {
    width: 0.75rem;
    color: $secondary !important;
  }
}

@media screen and (max-width: 56.25rem) {
  .experience-header .name {
    font-size: 1rem !important;
  }
}
</style>
