<template>
  <div>
    <label class="text-label mt-2">{{ label }}</label>
    <v-radio-group v-model="context" :error-messages="errorMessages" row>
      <v-radio
        v-for="{ name, value: radioValue } in radios"
        :key="name"
        :value="radioValue"
        :label="name" />
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: 'radio-group',
  props: {
    value: { type: [String, Number], default: null },
    errorMessages: { type: Array, default: () => [] },
    radios: { type: Array, default: () => [] },
    label: { type: String, default: '' }
  },
  computed: {
    context: {
      get: vm => vm.value,
      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-input--radio-group {
  margin: 0.25rem 0 1.25rem;

  .v-radio label::after {
    content: none !important;
  }
}
</style>
