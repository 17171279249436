<template>
  <div class="vw-container">
    <navbar />
    <v-main>
      <div v-if="showSelectedChoices" class="selected-choices">
        <p class="text-subtitle">{{ choicesPath.firstThree }}</p>
        <p v-if="choicesPath.fourth" class="text-subtitle my-1">
          {{ choicesPath.fourth }}
        </p>
      </div>
      <v-container fluid class="align-start justify-center fill-height">
        <router-view />
      </v-container>
    </v-main>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import capitalize from 'lodash/capitalize';
import compact from 'lodash/compact';
import Navbar from './common/Navbar';

export default {
  computed: {
    ...mapState('bookings', ['filter']),
    ...mapState('locations', ['isLoading']),
    showSelectedChoices: vm => !vm.isLoading && vm.$route.name !== 'thank-you',
    choicesPath() {
      const { outsidePackage, location, weekType, roomType } = this.filter;
      if (outsidePackage) return { firstThree: outsidePackage.name };
      const choices = compact([location?.name, weekType, roomType]);
      const capitalizedChoices = choices.map(it => capitalize(it));
      return {
        firstThree: capitalizedChoices.join(' | '),
        fourth: this.filter.formattedDate
      };
    }
  },
  methods: mapActions('settings', ['get']),
  created() {
    const theme = localStorage.getItem('vw_theme');
    if (theme === 'light') this.$vuetify.theme.dark = false;
    else this.$vuetify.theme.dark = true;
    return this.get();
  },
  components: { Navbar }
};
</script>

<style lang="scss" scoped>
.vw-container {
  min-height: 100%;

  .container {
    padding: 1rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.theme--dark .vw-container .container {
  background: $dark;
}

::v-deep .v-app-bar .v-toolbar__content {
  flex: 1;
  padding: 0.25rem 0.125rem !important;
}

::v-deep .v-main {
  height: 100%;

  .v-app-bar .v-toolbar__content {
    flex: 1;
    padding: 0.25rem 0.125rem !important;
  }

  .header {
    padding: 0 1rem;
    text-align: center;
  }

  .body {
    width: 100%;
    padding: 0 1rem 1.5rem;
    text-align: center;
  }

  p, ol, ul, td, .title, .subtitle, label {
    line-height: 1.125rem;
  }

  .text-title {
    color: $primary;
    font-size: 1.875rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.125rem;
  }

  .text-subtitle {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.2rem;
    letter-spacing: 0.0313rem;
  }

  .text-regular {
    font-size: 0.875rem;
    letter-spacing: 0.0313rem;
  }

  .text-label {
    font-size: 1.125rem;
    font-weight: 600;
    letter-spacing: 0.0313rem;
  }

  .selected-choices {
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-top: 1rem;

    .text-subtitle {
      margin: 0;
      text-align: center;
      color: $light-blue-grey;
      font-weight: 600;
    }
  }

  .progress-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55vh;
  }

  @media screen and (max-width: 48rem) {
    .text-title {
      font-size: 1.625rem;
    }

    .text-subtitle, .text-label {
      font-size: 0.9375rem;
    }

    .text-regular {
      font-size: 0.75rem;
    }

    .body {
      padding: 0 0.125rem 1.5rem;
    }
  }
}
</style>
