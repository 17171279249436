import Accommodation from './Accommodation';
import Boy from './Boy';
import Calendar from './Calendar';
import Checked from './Checked';
import Coinbase from './Coinbase';
import Finished from './Finished';
import Girl from './Girl';
import Info from './Info';
import Location from './Location';
import Lock from './Lock';
import Logo from './Logo';
import LogoOneColor from './LogoOneColor';
import Paypal from './Paypal';
import Shared from './Shared';
import Stripe from './Stripe';
import VWLogo from './VWLogo';

export default {
  Accommodation,
  Boy,
  Calendar,
  Checked,
  Coinbase,
  Finished,
  Girl,
  Info,
  Location,
  Lock,
  Logo,
  LogoOneColor,
  Paypal,
  Shared,
  Stripe,
  VWLogo
};
