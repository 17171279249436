<template>
  <v-footer height="64" fixed class="proceed-footer">
    <v-btn
      @click="$emit('proceed')"
      :disabled="disabled"
      color="cyan"
      depressed rounded>
      Next
    </v-btn>
  </v-footer>
</template>

<script>
export default {
  props: {
    disabled: { type: Boolean, default: false }
  }
};
</script>

<style lang="scss" scoped>
.proceed-footer .v-btn {
  min-width: 12rem;
  margin: 0 auto;
  color: #fff;
  font-weight: 600;
}
</style>
