<template>
  <div class="bookable-weeks-container">
    <div class="header">
      <h1 class="text-title">Period</h1>
      <h2 class="text-subtitle">{{ subtitle }}</h2>
      <v-chip-group v-if="uniqTags.length > 1" v-model="selection" mandatory>
        <v-chip v-for="tag in filterTags" :key="tag" :value="tag">
          {{ tag }}
        </v-chip>
      </v-chip-group>
    </div>
    <div class="weeks">
      <bookable-week-card
        v-for="bookableUnit in bookableUnits"
        :key="bookableUnit.weekId"
        :villa="bookableUnit"
        :is-weekend="isWeekend"
        v-bind="bookableUnit" />
    </div>
    <proceed-footer
      v-if="hasSelection"
      @proceed="$router.push({ name: 'crew-selection' })" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import BookableWeekCard from './Card';
import ProceedFooter from '@/main/components/common/ProceedFooter';
import uniq from 'lodash/uniq';

const subtitles = {
  week: 'Select the dates and villa that fit you / your group',
  weekend: 'Select the dates and weekend type that fit you / your group'
};

export default {
  name: 'bookable-week',
  data: () => ({ selection: 'All' }),
  computed: {
    ...mapGetters('weeks', ['fullWeeks', 'weekends']),
    ...mapState('bookings', ['filter']),
    isWeekend: vm => vm.filter.weekType === 'WEEKEND',
    isPrivateAccomodation: vm => vm.filter.roomType === 'PRIVATE',
    hasSelection: vm => !!vm.filter.week,
    subtitle: vm => vm.isWeekend ? subtitles.weekend : subtitles.week,
    items: vm => vm.isWeekend ? vm.weekends : vm.fullWeeks,
    sharedItems: vm => vm.items.filter(it => it.isShared),
    uniqTags: vm => uniq(vm.items.map(it => vm.isWeekend ? it.name : it.villa.name)),
    filterTags: vm => ['All', ...vm.uniqTags],
    filteredItems() {
      const items = this.isPrivateAccomodation ? this.items : this.sharedItems;
      if (this.selection === 'All') return items;
      return items.filter(it => {
        const tagName = this.isWeekend ? it.name : it.villa.name;
        return tagName === this.selection;
      });
    },
    bookableUnits: vm => vm.filteredItems.map(week => {
      const { id, villa, price, isShared, year, assets, startDate: start, endDate: end } = week;
      return { ...villa, price, isShared, year, start, end, assets, week, weekId: id };
    })
  },
  components: { BookableWeekCard, ProceedFooter }
};
</script>

<style lang="scss" scoped>
@import '@/main/stylesheets/vuetify';

.bookable-weeks-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .weeks {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 1rem -1rem;

    ::v-deep .v-card {
      max-width: calc(100% - 2rem);
    }
  }
}
</style>
