<template>
  <vw-card
    @click="selectPackage(outsidePackage.id)"
    :assets=" outsidePackage.assets"
    :has-available-capacity="!isBooked"
    :is-selected="isSelected">
    <template #body>
      <v-card-title class="text-title">{{ outsidePackage.name }}</v-card-title>
      <v-card-text class="text-regular slots">
        <div v-if="outsidePackage.ladies" class="mx-1">
          <span class="font-weight-bold">{{ outsidePackage.ladies }}</span>
          {{ pluralize('lady', outsidePackage.ladies) }}
        </div>
        <div v-if="outsidePackage.gentlemen" class="mx-1">
          <span class="font-weight-bold">{{ outsidePackage.gentlemen }}</span>
          {{ pluralize('gentleman', outsidePackage.gentlemen) }}
        </div>
        <div v-if="outsidePackage.children" class="mx-1">
          <span class="font-weight-bold">{{ outsidePackage.children }}</span>
          {{ pluralize('child', outsidePackage.children) }}
        </div>
        <div v-if="outsidePackage.infants" class="mx-1">
          <span class="font-weight-bold">{{ outsidePackage.infants }}</span>
          {{ pluralize('infant', outsidePackage.infants) }}
        </div>
      </v-card-text>
    </template>
  </vw-card>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import api from '@/admin/api/outsidePackage';
import get from 'lodash/get';
import pluralize from 'pluralize';
import transform from 'lodash/transform';

const processActivities = acitvities => transform(acitvities, (acc, it) => {
  const { packageExperience: { slots, price, group }, ...activity } = it;
  const type = activity.isAmenity ? 'amenities' : 'experiences';
  acc[type].push({ ...activity, included: false, price, group, slots });
}, { amenities: [], experiences: [] });

export default {
  name: 'package-card',
  props: {
    outsidePackage: { type: Object, required: true }
  },
  computed: {
    ...mapState('bookings', ['filter', 'weekFormattedAmenities']),
    isBooked: vm => !!vm.outsidePackage.booking,
    isSelected() {
      const packageId = get(this.filter, 'outsidePackage.id');
      return packageId === this.outsidePackage.id;
    },
    buttonActionText() {
      const { isSelected, isBooked } = this;
      if (isBooked) return 'Booked';
      return isSelected ? 'Selected' : 'Select';
    }
  },
  methods: {
    pluralize,
    ...mapMutations('bookings', [
      'updateFilter',
      'setWeekFormattedExperiences',
      'setWeekFormattedAmenities',
      'setDefaultsForStep'
    ]),
    async selectPackage() {
      if (!this.isSelected) {
        const data = await api.get(this.outsidePackage.id);
        const { id, name, ladies, gentlemen, children, infants, deposit } = data;
        const { amenities, experiences } = processActivities(data.experiences);
        const outsidePackage = { id, name, amenities, experiences, deposit };
        this.setDefaultsForStep({ step: 0 });
        this.updateFilter({ ladies, gentlemen, children, infants, outsidePackage });
        this.setWeekFormattedExperiences();
        this.setWeekFormattedAmenities();
      }
      const hasAmenities = this.weekFormattedAmenities.length;
      if (hasAmenities) return this.$router.push({ name: 'amenities' });
      return this.$router.push({ name: 'experiences' });
    }
  }
};
</script>

<style lang="scss" scoped>
.v-card.v-sheet {
  width: 21.375rem;
  margin: 1rem;
  border-radius: 20px !important;
  text-align: center;

  .v-card__title {
    word-break: break-word;
  }

  .v-card__actions .v-btn {
    padding: 1.375rem;
    color: white;
    font-weight: bold;
    background: $secondary !important;
    border-radius: inherit !important;
  }

  ::v-deep .v-icon svg {
    fill: white;
  }

  .v-card__actions .v-btn.v-btn--disabled {
    color: #fff !important;
    background: #d94565 !important;
    opacity: 0.7;
  }

  &.selected {
    .content {
      border: 1px solid $primary;
    }

    .v-card__actions .v-btn {
      color: black;
      background: $primary !important;
    }
  }
}
</style>
