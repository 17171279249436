<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div class="payment-info" v-html="paymentInfo"></div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'payment-info',
  computed: mapState('settings', ['paymentInfo'])
};
</script>

<style lang="scss" scoped>
.payment-info ::v-deep {
  h2 {
    margin: 0.8125rem 0 0.3125rem;
    font-size: 1.125rem;
    font-weight: 500;
  }

  @media screen and (max-width: 48rem) {
    h2 {
      margin: 0.8125rem 0 0.3125rem;
      font-size: 1rem;
    }
  }
}
</style>
