<template>
  <vw-card
    @click="setRoomTypeAndProceed"
    :assets="assets"
    :has-available-capacity="isAccommodationAvailable(type)"
    :is-selected="isSelected">
    <template #body>
      <v-icon v-if="isShared" class="shared">$shared</v-icon>
      <v-icon v-else class="private-icon">mdi-diamond-stone</v-icon>
      <p class="text-title">{{ name }}</p>
      <p class="text-subtitle">{{ tagline }}</p>
      <template v-if="isAccommodationAvailable(type)">
        <p class="text-regular my-1">Available</p>
        <p class="text-regular">{{ availableAccomodations }}</p>
      </template>
    </template>
    <template #footer>
      <p class="text-regular">from {{ getMinPrice }} per person</p>
    </template>
  </vw-card>
</template>

<script>
import { formatAvailability, getMinPrice } from '@/main/common/utils';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { PREMIUM } from '../../common/config';
import sumBy from 'lodash/sumBy';

export default {
  name: 'room-type-card',
  props: {
    name: { type: String, required: true },
    type: { type: String, required: true },
    assets: { type: Array, required: true },
    tagline: { type: String, required: true }
  },
  computed: {
    ...mapState('bookings', ['filter']),
    ...mapGetters('weeks', [
      'fullWeeks',
      'weekends',
      'privateWeekVillas',
      'privateWeekendVillas',
      'isAccommodationAvailable'
    ]),
    isWeek: vm => vm.filter.weekType === 'WEEK',
    weeks: vm => vm.isWeek ? vm.fullWeeks : vm.weekends,
    isShared: vm => vm.type === 'SHARED',
    privateVillas: vm => vm.isWeek ? vm.privateWeekVillas : vm.privateWeekendVillas,
    isSelected: vm => vm.filter.roomType === vm.type,
    rooms: vm => sumBy(vm.weeks, 'villa.rooms.length'),
    getMinPrice() {
      const { privateVillas, weeks, isShared } = this;
      return isShared ? getMinPrice(weeks) : getMinPrice(privateVillas, PREMIUM);
    },
    availableAccomodations() {
      if (this.isShared) return formatAvailability(this.rooms, 'bedroom');
      return formatAvailability(this.privateVillas.length, 'villa');
    }
  },
  methods: {
    ...mapMutations('bookings', ['updateFilter', 'setDefaultsForStep']),
    setRoomTypeAndProceed() {
      const { isSelected, type: roomType, $router } = this;
      if (isSelected) return $router.push({ name: 'bookable-week' });
      this.setDefaultsForStep({ step: 2 });
      this.updateFilter({ roomType });
      $router.push({ name: 'bookable-week' });
    }
  }
};
</script>

<style lang="scss" scoped>
.v-icon.shared ::v-deep svg {
  width: 6rem;
  height: 2rem;
  margin-bottom: 1rem;
  fill: $primary;
}

.private-icon {
  margin-bottom: 1.25rem;
  color: $primary;
  font-size: 2.375rem;
}
</style>
