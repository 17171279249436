<template>
  <vw-card
    @click="setLocationAndProceed"
    :assets="location.assets"
    :has-available-capacity="isAvailable"
    :is-selected="isSelected">
    <template #body>
      <p class="text-title">{{ location.name }}</p>
      <p class="text-subtitle">{{ description }}</p>
      <template v-if="isAvailable">
        <p class="text-regular my-1">Available</p>
        <p class="text-regular">{{ availibility }}</p>
      </template>
    </template>
    <template #footer>
      <p class="text-regular">{{ minPrice }}</p>
    </template>
  </vw-card>
</template>

<script>
import { formatAvailability, getMinPrice } from '@/main/common/utils';
import { mapMutations, mapState } from 'vuex';
import get from 'lodash/get';

const DEFAULT_DESCRIPTION = 'The original home of The Villa Week';

export default {
  name: 'location-card',
  props: {
    location: { type: Object, required: true }
  },
  computed: {
    ...mapState('bookings', ['filter']),
    description: vm => vm.location.description || DEFAULT_DESCRIPTION,
    isAvailable: vm => vm.location.weeks.length,
    availibility() {
      const { weeks } = this.location;
      const weekends = weeks.filter(it => it.isWeekend).length;
      return `${formatAvailability(weeks.length - weekends, 'week')}
      | ${formatAvailability(weekends, 'weekend')}`;
    },
    minPrice() {
      if (!this.isAvailable) return;
      return `from ${getMinPrice(this.location.weeks)} per person`;
    },
    isSelected() {
      const locationId = get(this.filter, 'location.id');
      return locationId === this.location.id;
    }
  },
  methods: {
    ...mapMutations('bookings', ['updateFilter', 'setDefaultsForStep']),
    setLocationAndProceed() {
      const { isSelected, location, $router } = this;
      if (isSelected) return $router.push({ name: 'week-type' });
      this.updateFilter({ location });
      this.setDefaultsForStep({ step: 0 });
      $router.push({ name: 'week-type' });
    }
  }
};
</script>

<style lang="scss" scoped>
.text-subtitle {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
