<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="41.4372mm"
    height="43.2943mm"
    version="1.1"
    style="shape-rendering: geometricPrecision; text-rendering: geometricPrecision; image-rendering: optimizeQuality; fill-rule: evenodd; clip-rule: evenodd;"
    viewBox="0 0 1145.25 1196.58"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"></metadata>
      <path class="fil0" d="M30.49 511.33l1096.22 0 0 128.97 -1096.22 0 0 -128.97zm0 174.34l336.56 0 0 336.56 -336.56 0 0 -336.56zm379.83 0l336.56 0 0 336.56 -336.56 0 0 -336.56zm379.83 0l336.56 0 0 336.56 -336.56 0 0 -336.56zm-759.66 381.94l1096.22 0 0 128.97 -1096.22 0 0 -128.97zm542.14 -926.46l-572.63 334.36 0 -140.91 572.63 -334.6 572.63 334.6 0 140.91 -572.63 -334.36z">
      </path>
    </g>
  </svg>
</template>

<script>
export default { name: 'accommodation-icon' };
</script>
