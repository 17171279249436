<template>
  <div class="terms-container">
    <v-sheet class="mb-6">
      <terms-content />
    </v-sheet>
    <validation-provider
      v-slot="{ errors }"
      :debounce="1000"
      :rules="{ required: { allowFalse: false } }"
      name="Terms">
      <v-checkbox
        v-model="context"
        :error-messages="errors"
        label="I agree to the terms & conditions and my ladies to gentlemen ratio." />
    </validation-provider>
  </div>
</template>

<script>
import TermsContent from './Content';

export default {
  name: 'terms',
  props: {
    value: { type: Boolean, default: false }
  },
  computed: {
    context: {
      get: vm => vm.value,
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  components: { TermsContent }
};
</script>
<style lang="scss" scoped>
::v-deep .v-sheet {
  height: 10.5rem;
  padding: 0.875rem;
  border-radius: 0.625rem;
  overflow: hidden;
  overflow-y: scroll;
  border: 1px solid $light-grey;
  border-left-width: 10px;
}

@media screen and (max-width: 48rem) {
  ::v-deep .v-sheet {
    padding: 0.625rem;
    font-size: 0.75rem;
  }
}
</style>
