<template>
  <div class="amenities-container">
    <div class="header">
      <h1 class="text-title">Amenities</h1>
      <h2 class="text-subtitle grey--text">
        Start with picking the Amenities you’ve always wanted on your holiday.
      </h2>
      <p class="text-regular total-guests">Total guests: {{ guests }}</p>
    </div>
    <div class="body mb-8">
      <amenity-list />
      <template v-if="optionalAmenities.length && !isPackage">
        <v-btn
          @click="addFullAmenityPackage"
          :color="isAmenityFullPackageSelected ? 'primary' : 'cyan'"
          :outlined="!isAmenityFullPackageSelected"
          rounded
          class="discount-btn elevation-0">
          <span v-if="!isAmenityFullPackageSelected">
            Add full package and get
            <span class="discount">{{ format(discountAmount) }}</span>
            discount
          </span>
          <span v-else>
            <v-icon left>mdi-check</v-icon>Full package applied
          </span>
        </v-btn>
        <v-btn
          @click="resetAmenities"
          :disabled="!amenities.length"
          color="error"
          text rounded small
          class="d-block mx-auto my-1">
          Dissmiss selection
        </v-btn>
      </template>
    </div>
    <v-footer height="64" fixed>
      <div class="content-row">
        <p class="price mb-1">{{ format(pricePerPerson) }}pp</p>
        <p class="text-regular mb-0"> Total price: {{ format(price) }}</p>
        <div class="payment-info">
          <v-icon class="info-icon">$info</v-icon>
          <span
            @click="show.paymentProcedureModal = true"
            class="text-regular payment-text">How do I pay?
          </span>
        </div>
      </div>
      <v-btn
        v-if="!filter.outsidePackage || weekFormattedExperiences.length"
        @click="$router.push({ name: 'experiences' })"
        color="cyan"
        dark rounded depressed>
        Proceed
      </v-btn>
      <v-btn
        v-else
        @click="$router.push({ name: 'confirm-booking' })"
        :disabled="disableCheckout"
        :dark="!disableCheckout"
        color="cyan"
        rounded depressed>
        Checkout
      </v-btn>
    </v-footer>
    <vw-dialog v-model="show.paymentProcedureModal" title="How do I pay?">
      <template #content>
        <payment-info />
      </template>
    </vw-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import AmenityList from './AmenityList';
import { currencyFormatter } from '@/main/common/utils';
import { DISCOUNT } from '@/main/common/config';
import PaymentInfo from '@/main/components/common/PaymentInfo';

export default {
  name: 'amenities',
  data: () => ({
    show: { paymentProcedureModal: false }
  }),
  computed: {
    ...mapState('bookings', ['filter', 'amenities', 'weekFormattedExperiences']),
    ...mapGetters('bookings', [
      'guests', 'price', 'pricePerPerson',
      'amenityFullPackagePrice', 'isAmenityFullPackageSelected'
    ]),
    ...mapGetters('bookings', ['optionalAmenities']),
    discountAmount: vm => vm.amenityFullPackagePrice * DISCOUNT,
    isPackage: vm => !!vm.filter.outsidePackage,
    disableCheckout: vm => vm.isPackage && !vm.price
  },
  methods: {
    ...mapActions('bookings', ['addFullAmenityPackage']),
    ...mapMutations('bookings', ['validateStep', 'resetAmenities']),
    format: currencyFormatter().format
  },
  mounted() {
    this.validateStep({ step: 6 });
  },
  components: { AmenityList, PaymentInfo }
};
</script>

<style lang="scss" scoped>
.amenities-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 33.25rem;
  overflow: hidden;

  .text-regular.text-regular {
    color: $light-blue-grey;
  }

  .info-icon {
    width: 1.125rem;
    height: 1.125rem;
    fill: $secondary;
    outline: none;
    cursor: pointer;
  }

  .total-guests.total-guests {
    margin: 1.875rem 0 2.5rem 0;
    font-size: 1.125rem;
    font-weight: 600;
    letter-spacing: 0;
    text-transform: uppercase;
  }

  .discount-btn.v-btn {
    height: 3rem;
    font-weight: normal;

    &:not(.v-btn--outlined).primary {
      color: $black;
    }

    .discount {
      font-weight: 600;
    }
  }
}

::v-deep .v-footer.v-sheet {
  display: flex;
  flex-flow: row nowrap;
  padding: 0.75rem 0.25rem 0.75rem 1rem;
  z-index: 10;

  .v-btn {
    font-weight: 600;
  }

  .content-row {
    position: relative;
    width: calc(100% - 8rem);

    .price {
      font-size: 1.125rem;
      font-weight: 600;
    }

    .text-regular {
      text-align: start;
    }
  }
}

::v-deep .v-footer.v-sheet .payment-info {
  display: flex;
  align-items: center;
  position: absolute;
  right: 1rem;
  bottom: 1rem;

  .payment-text {
    color: $secondary;
  }

  .info-icon {
    width: 0.875rem;
    height: 0.875rem;
    margin-right: 0.3125rem;
  }
}

@media screen and (max-width: 56.25rem) {
  ::v-deep .discount-btn.v-btn {
    width: 100%;
    font-size: 0.75rem;
  }

  ::v-deep .v-footer.v-sheet .content-row {
    .price {
      font-size: 1rem;
    }

    .payment-info {
      right: 0.75rem;
      bottom: 1.25rem;
    }
  }
}
</style>
