<template>
  <div class="experience-list-container">
    <v-expansion-panels
      v-if="includedExperiences.length"
      :value="0"
      tile flat
      class="already-included">
      <v-expansion-panel>
        <v-expansion-panel-header class="mb-3">
          What's Already Included?
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <included-experience-list />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <h3 v-if="includedExperiences.length" class="sub-header">
      Pick Additional Experiences
    </h3>
    <experience-list
      v-if="optionalExperiences.length"
      :experiences="optionalExperiences" />
    <p v-else class="no-data">No Experiences to pick</p>
  </div>
</template>

<script>
import ExperienceList from './List';
import IncludedExperienceList from '@/main/components/common/IncludedExperienceList';
import { mapGetters } from 'vuex';

export default {
  name: 'experiences',
  computed: mapGetters('bookings', ['includedExperiences', 'optionalExperiences']),
  components: { IncludedExperienceList, ExperienceList }
};
</script>

<style lang="scss" scoped>
@import '@/main/stylesheets/vuetify';

::v-deep .v-expansion-panels {
  margin-bottom: 1.25rem;

  .v-expansion-panel-header {
    font-size: 1.5rem;

    .v-icon {
      font-size: 1.375rem;
    }
  }
}

.sub-header {
  padding-left: 0.25rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: start;
}

.no-data {
  padding: 1rem;
  color: $light-blue-grey;
  font-size: 1rem;
}

@media screen and (max-width: 56.25rem) {
  .sub-header {
    font-size: 1.25rem;
  }

  .no-data {
    font-size: 0.875rem;
  }
}
</style>
