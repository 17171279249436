<template>
  <div class="actions">
    <v-btn @click="close" color="danger" rounded depressed dark>
      {{ isChoosen ? 'Remove' : 'Cancel' }}
    </v-btn>
    <span v-if="experience.soldOut" class="text-regular sold-out">SOLD OUT</span>
    <div v-else class="d-flex align-center">
      <v-select
        v-if="!experience.group"
        v-model="selectedSlots"
        :items="selectionSlots"
        :menu-props="{ maxHeight: 148 }"
        append-icon="mdi-chevron-down"
        label="No. of slots to add"
        dense outlined
        class="exp-slot-select mr-3" />
      <v-btn
        @click="upsert"
        color="cyan" rounded depressed dark>
        {{ isChoosen ? 'Update' : 'Add' }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

const createArray = value => new Array(value).fill(null);

export default {
  name: 'experience-actions',
  props: {
    experience: { type: Object, required: true },
    isChoosen: { type: Boolean, default: false }
  },
  data: vm => ({ selectedSlots: vm.experience.selectedSlots }),
  computed: {
    selectionSlots() {
      const { initialSlots } = this.experience;
      return createArray(initialSlots).map((_, slot) => slot + 1);
    }
  },
  methods: {
    ...mapMutations('bookings', [
      'removeSelectedExperience',
      'upsertSelectedExperience',
      'updateWeekFormattedSlots'
    ]),
    upsert() {
      const payload = { experience: this.experience, slots: this.selectedSlots };
      this.upsertSelectedExperience(payload);
      this.updateWeekFormattedSlots(payload);
      this.$emit('contract');
    },
    close() {
      const { experience, isChoosen } = this;
      if (isChoosen) this.removeSelectedExperience(experience);
      this.$emit('contract');
    }
  }
};
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0.875rem;

  ::v-deep .v-btn .v-btn__content {
    font-size: 0.75rem;
    font-weight: 600;
  }

  span.sold-out {
    color: $danger;
    font-size: 0.625rem;
    font-weight: 600;
  }
}

::v-deep .exp-slot-select {
  &.theme--light {
    background: $white;
  }

  &.v-select, .v-input__control, .v-input__slot {
    width: 4rem;
    height: 2.5rem;
    flex: 0;
  }

  .v-input__slot {
    padding-right: 0.5rem;

    fieldset legend {
      width: 0 !important;
    }

    .v-label {
      top: -0.125rem;
      left: -3.375rem !important;
      font-size: 0.75rem;
      word-break: break-word;
      overflow: visible;
      white-space: pre-wrap;
      line-height: 0.875rem;
      text-align: end;
    }

    .v-label.v-label--active {
      transform: none;
      max-width: 90%;
    }
  }

  .v-input__control .v-input__slot fieldset {
    width: 4rem;
    height: 2.75rem;
  }

  .v-select__selections {
    flex-wrap: nowrap;

    .v-select__selection--comma {
      overflow: visible;
    }
  }

  .v-select__slot .v-input__icon--append {
    margin: 0.125rem 0 0 1rem;

    .v-icon {
      font-size: 0.875rem;
    }
  }
}
</style>
