<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="50mm"
    height="50mm"
    version="1.1"
    style="shape-rendering: geometricPrecision; text-rendering: geometricPrecision; image-rendering: optimizeQuality; fill-rule: evenodd; clip-rule: evenodd;"
    viewBox="0 0 1366.42 1366.42"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"></metadata>
      <path class="fil0" d="M727.98 1288.86l0.02 0 -44.79 77.56 -175.3 -303.65 -94.9 164.4 -10.82 18.69 -27.23 47.19c-222.44,-112.67 -374.94,-343.44 -374.94,-609.85 0,-154.86 51.57,-297.66 138.42,-412.25l341.84 592.12 -43.84 75.91 -300.18 -519.96c-38.65,79.88 -60.33,169.5 -60.33,264.18 -0.01,211.19 107.82,397.13 271.38,505.94l8.9 -15.46 255.54 -442.6 43.84 75.9 -103.85 179.86 131.47 227.74 544.8 -943.64c86.87,114.57 138.42,257.39 138.42,412.25 -0.01,266.41 -152.48,497.17 -374.93,609.84l-132.96 -230.28 -130.55 226.1zm59.08 -349.88l-477.81 -827.59c107.44,-70.42 235.9,-111.39 373.96,-111.39 138.05,0 266.52,40.97 373.95,111.39l-330.12 571.81 103.84 179.86 -43.83 75.91zm166.39 -799.75l-270.25 468.07 -270.24 -468.07c81.39,-40.51 173.16,-63.31 270.24,-63.31 97.09,-0.01 188.86,22.8 270.24,63.32zm65.7 1049.91c163.55,-108.81 271.36,-294.76 271.36,-505.94 -0.01,-94.68 -21.67,-184.31 -60.33,-264.18l-327.83 567.83 116.8 202.29 0 0z"></path>
    </g>
  </svg>
</template>

<script>
export default { name: 'logo-one-color-icon' };
</script>
