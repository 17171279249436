<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="8.3689mm"
    height="16.8296mm"
    version="1.1"
    style="shape-rendering: geometricPrecision; text-rendering: geometricPrecision; image-rendering: optimizeQuality; fill-rule: evenodd; clip-rule: evenodd;"
    viewBox="0 0 312.23 627.88"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"></metadata>
      <path class="fil0" d="M-0 373.52c0,13.78 3.84,23.49 21.53,23.49 19.58,0 21.59,-11.48 25.85,-26.98l30.38 -102.69c5.79,-18.88 27.6,-16.25 22.63,4.8l-20.84 73.31c-3.9,13.51 -26.72,91.23 -26.72,98.53 0,17.69 16.73,13.7 39.14,13.7l0 135.03c0,45.3 36.33,37.9 46.21,26.65 4.78,-5.44 6.62,-13.25 6.62,-22.73l0 -138.94 23.49 0 0 148.73c0,27.27 50.88,29.37 50.88,-1.96l0 -146.77c56.01,0 46.05,4.53 16.08,-102.18 -4.8,-17.09 -23.91,-78.24 -23.91,-91.55 0,-4.06 5.72,-9.78 7.83,-9.78 16.17,0 16.28,18.2 20.79,32.04 4.42,13.57 8.59,28.37 12.07,40.77 4.04,14.45 8.01,27.38 11.92,40.92 5.72,19.79 6.9,29.12 27.63,29.12 39.21,0 11.73,-57.81 5.11,-77.52 -7.83,-23.36 -26.63,-96.63 -36.45,-112.28 -8.52,-13.58 -26.87,-25.46 -48.9,-25.46l-109.59 0c-52.68,0 -58.24,45.91 -68.24,78.53 -3.57,11.64 -33.52,103.53 -33.52,113.25zm74.36 -291.58c0,10.49 4.52,25.89 7.81,33.28 7.67,17.21 23.89,33.43 41.1,41.09 6.93,3.09 23.28,8.02 33.17,7.73 10.91,-0.31 25.38,-4.16 33.39,-7.73 47.8,-21.3 68.29,-87.32 23.97,-131.63 -8.16,-8.16 -13.06,-12.26 -23.97,-17.12 -22.65,-10.09 -43.92,-10.09 -66.56,0 -17.21,7.66 -33.43,23.89 -41.1,41.09 -3.29,7.39 -7.81,22.79 -7.81,33.28z">
      </path>
    </g>
  </svg>
</template>

<script>
export default { name: 'girl-icon' };
</script>
