<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="27.9867mm"
    height="27.9867mm"
    version="1.1"
    style="shape-rendering: geometricPrecision; text-rendering: geometricPrecision; image-rendering: optimizeQuality; fill-rule: evenodd; clip-rule: evenodd;"
    viewBox="0 0 914.32 914.32"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"></metadata>
      <g id="_105553540964672">
        <path class="fil0" d="M466.49 839.68c-67.14,0 -117.74,-8.51 -174.25,-35.67 -195.67,-94 -270.15,-328.15 -181.93,-511.78 141.49,-294.51 560.13,-287.55 695.24,3.14 111.81,240.57 -59.23,544.3 -339.05,544.3zm-466.49 -382.52c0,249.77 207.39,457.16 457.16,457.16 249.78,0 457.16,-207.39 457.16,-457.16 0,-249.76 -207.24,-457.16 -457.16,-457.16 -249.91,0 -457.16,207.25 -457.16,457.16z"></path>
        <path class="fil0" d="M317.22 391.85l0 41.98c36.22,-8.67 74.64,-2.28 74.64,23.33l0 209.92c0,39.46 -33.27,32.65 -69.97,32.65l0 37.32 275.23 0 0 -37.32c-33.33,0 -65.31,6.15 -65.31,-32.65l0 -279.9c-76.7,0 -137.24,4.67 -214.59,4.67z"></path>
        <path class="fil0" d="M368.53 256.57c0,99.74 158.61,111.38 158.61,-4.67 0,-99.05 -158.61,-101.75 -158.61,4.67z"></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default { name: 'info-icon' };
</script>
