import generateActions from '@/common/store/helpers/actions';
const { get, fetch, save, remove } = generateActions('/bookings');

export const addFullExperiencePackage = ({ commit, getters }) => {
  const { optionalExperiences } = getters;
  const availableOptionalExperiences = optionalExperiences.filter(it => !it.soldOut);
  commit('setFullExperiencePackage', availableOptionalExperiences);
};

export const addFullAmenityPackage = ({ commit, getters }) => {
  const { optionalAmenities } = getters;
  const availableOptionalAmenity = optionalAmenities.filter(it => !it.soldOut);
  commit('setFullAmenityPackage', availableOptionalAmenity);
};

export { get, fetch, save, remove };
