<template>
  <div class="mt-3 mb-8">
    <v-expansion-panels v-model="expanded" tile accordion>
      <v-expansion-panel
        v-for="exp in experiences"
        :key="exp.id"
        :ref="`exp-${exp.id}`"
        :class="{ choosen: isChoosen(exp) }">
        <experience-panel-header v-bind="exp" :is-choosen="isChoosen(exp)" />
        <v-expansion-panel-content>
          <experience-carousel
            @contract="expanded = null"
            v-bind="exp"
            :assets="exp.assets" />
          <template v-if="!isPackage">
            <child-experiences
              v-if="exp.childExperiences.length"
              :experiences="exp.childExperiences"
              :parent-name="exp.name" />
            <recommendations
              v-if="exp.recommendations.length"
              @reveal-experience="revealExperience"
              :experiences="exp.recommendations" />
          </template>
          <experience-actions
            :key="exp.id"
            @update-slots="$emit('update-slots', $event)"
            @contract="expanded = null"
            v-bind="{ experience: exp, isChoosen: isChoosen(exp) }" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import ChildExperiences from './ChildExperiences';
import ExperienceActions from './Actions';
import ExperienceCarousel from './Carousel';
import ExperiencePanelHeader from './PanelHeader';
import findIndex from 'lodash/findIndex';
import { mapGetters } from 'vuex';
import Recommendations from './Recommendations';

export default {
  name: 'experience-list',
  props: {
    experiences: { type: Array, required: true }
  },
  data: () => ({ expanded: null }),
  computed: mapGetters('bookings', ['isPackage', 'selectedExperienceIds']),
  methods: {
    isChoosen({ id }) {
      return this.selectedExperienceIds.includes(id);
    },
    revealExperience({ id }) {
      const refs = this.$refs[`exp-${id}`];
      if (!refs) return;
      const opts = { behavior: 'smooth', block: 'nearest', inline: 'nearest' };
      refs[0].$el.scrollIntoView(opts);
      this.expanded = findIndex(this.experiences, { id });
    }
  },
  components: {
    ChildExperiences,
    ExperienceActions,
    ExperienceCarousel,
    ExperiencePanelHeader,
    Recommendations
  }
};
</script>

<style lang="scss" scoped>
@import '@/main/stylesheets/vuetify';

::v-deep .v-expansion-panels--tile {
  .v-expansion-panel .actions, .v-expansion-panel::before {
    border-radius: inherit;
  }

  .v-expansion-panel:first-child .v-carousel {
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
  }
}

::v-deep .v-expansion-panels.v-expansion-panels--accordion {
  padding: 0;

  .additional-info {
    display: flex;

    .text-regular {
      color: $light-blue-grey;
      font-size: 0.5625rem;
      font-weight: 600;
    }
  }

  .v-expansion-panel-header {
    height: 4rem;
  }
}

::v-deep .addon-experiences-container, ::v-deep .child-experiences-container {
  .sub-exp-heading {
    text-align: left;
    font-size: 0.75rem;
    font-weight: 500;
  }

  .exp-name {
    max-width: 44%;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    letter-spacing: 0.0625rem;
    text-align: left;
  }

  .information {
    display: flex;
    align-items: center;

    .info-icon, .external-link-icon {
      margin-left: auto !important;
    }
  }

  .experience, .sub-exp-heading {
    padding: 0.75rem 1rem 0;
  }
}
</style>
