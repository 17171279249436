<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="114.47mm"
    height="28.5436mm"
    version="1.1"
    style="shape-rendering: geometricPrecision; text-rendering: geometricPrecision; image-rendering: optimizeQuality; fill-rule: evenodd; clip-rule: evenodd;"
    viewBox="0 0 11448.72 2854.8"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"></metadata>
      <path class="fil0" d="M1082.12 0c132.53,0 252.56,53.74 339.44,140.62 86.87,86.88 140.62,206.89 140.62,339.44 0,132.53 -53.75,252.56 -140.62,339.44 -86.88,86.87 -206.91,140.62 -339.44,140.62 -132.55,0 -252.56,-53.75 -339.44,-140.62 -86.88,-86.88 -140.62,-206.91 -140.62,-339.44 0,-132.55 53.74,-252.56 140.62,-339.44 86.88,-86.88 206.89,-140.62 339.44,-140.62zm127.31 352.75c-32.56,-32.56 -77.58,-52.71 -127.31,-52.71 -49.73,0 -94.74,20.14 -127.31,52.71 -32.56,32.56 -52.71,77.58 -52.71,127.31 0,49.73 20.14,94.74 52.71,127.31 32.55,32.55 77.58,52.71 127.31,52.71 49.73,0 94.75,-20.15 127.31,-52.71 32.55,-32.55 52.71,-77.58 52.71,-127.31 0,-49.73 -20.15,-94.75 -52.71,-127.31zm-1025.42 376.4l770.21 601.14 0 1524.5 -300.05 0 0 -1379.5 -654.17 -510.57 184.01 -235.58zm1954.17 235.58l-654.17 510.57 0 1379.5 -300.05 0 0 -1524.5 770.21 -601.14 184.01 235.58z"></path>
      <path class="fil0" d="M5737.39 0c132.53,0 252.56,53.74 339.44,140.62 86.87,86.88 140.62,206.89 140.62,339.44 0,132.53 -53.75,252.56 -140.62,339.44 -86.88,86.87 -206.91,140.62 -339.44,140.62 -132.55,0 -252.56,-53.75 -339.44,-140.62 -86.88,-86.88 -140.62,-206.91 -140.62,-339.44 0,-132.55 53.74,-252.56 140.62,-339.44 86.88,-86.88 206.89,-140.62 339.44,-140.62zm127.31 352.75c-32.56,-32.56 -77.58,-52.71 -127.31,-52.71 -49.73,0 -94.74,20.14 -127.31,52.71 -32.56,32.56 -52.71,77.58 -52.71,127.31 0,49.73 20.14,94.74 52.71,127.31 32.55,32.55 77.58,52.71 127.31,52.71 49.73,0 94.75,-20.15 127.31,-52.71 32.55,-32.55 52.71,-77.58 52.71,-127.31 0,-49.73 -20.15,-94.75 -52.71,-127.31zm-1025.42 376.4l770.21 601.14 0 1524.5 -300.05 0 0 -1379.5 -654.17 -510.57 184.01 -235.58zm1954.17 235.58l-654.17 510.57 0 1379.5 -300.05 0 0 -1524.5 770.21 -601.14 184.01 235.58z"></path>
      <path class="fil0" d="M8065.02 0c132.53,0 252.56,53.74 339.44,140.62 86.87,86.88 140.62,206.89 140.62,339.44 0,132.53 -53.75,252.56 -140.62,339.44 -86.88,86.87 -206.91,140.62 -339.44,140.62 -132.55,0 -252.56,-53.75 -339.44,-140.62 -86.88,-86.88 -140.62,-206.91 -140.62,-339.44 0,-132.55 53.74,-252.56 140.62,-339.44 86.88,-86.88 206.89,-140.62 339.44,-140.62zm127.31 352.75c-32.56,-32.56 -77.58,-52.71 -127.31,-52.71 -49.73,0 -94.74,20.14 -127.31,52.71 -32.56,32.56 -52.71,77.58 -52.71,127.31 0,49.73 20.14,94.74 52.71,127.31 32.55,32.55 77.58,52.71 127.31,52.71 49.73,0 94.75,-20.15 127.31,-52.71 32.55,-32.55 52.71,-77.58 52.71,-127.31 0,-49.73 -20.15,-94.75 -52.71,-127.31zm-1025.42 376.4l770.21 601.14 0 1524.5 -300.05 0 0 -1379.5 -654.17 -510.57 184.01 -235.58zm1954.17 235.58l-654.17 510.57 0 1379.5 -300.05 0 0 -1524.5 770.21 -601.14 184.01 235.58z"></path>
      <path class="fil0" d="M10392.66 0c132.53,0 252.56,53.74 339.44,140.62 86.87,86.88 140.62,206.89 140.62,339.44 0,132.53 -53.75,252.56 -140.62,339.44 -86.88,86.87 -206.91,140.62 -339.44,140.62 -132.55,0 -252.56,-53.75 -339.44,-140.62 -86.88,-86.88 -140.62,-206.91 -140.62,-339.44 0,-132.55 53.74,-252.56 140.62,-339.44 86.88,-86.88 206.89,-140.62 339.44,-140.62zm127.31 352.75c-32.56,-32.56 -77.58,-52.71 -127.31,-52.71 -49.73,0 -94.74,20.14 -127.31,52.71 -32.56,32.56 -52.71,77.58 -52.71,127.31 0,49.73 20.14,94.74 52.71,127.31 32.55,32.55 77.58,52.71 127.31,52.71 49.73,0 94.75,-20.15 127.31,-52.71 32.55,-32.55 52.71,-77.58 52.71,-127.31 0,-49.73 -20.15,-94.75 -52.71,-127.31zm-1025.42 376.4l770.21 601.14 0 1524.5 -300.05 0 0 -1379.5 -654.17 -510.57 184.01 -235.58zm1954.17 235.58l-654.17 510.57 0 1379.5 -300.05 0 0 -1524.5 770.21 -601.14 184.01 235.58z"></path>
      <path class="fil0" d="M3409.75 0c132.53,0 252.56,53.74 339.44,140.62 86.87,86.88 140.62,206.89 140.62,339.44 0,132.53 -53.75,252.56 -140.62,339.44 -86.88,86.87 -206.91,140.62 -339.44,140.62 -132.55,0 -252.56,-53.75 -339.44,-140.62 -86.88,-86.88 -140.62,-206.91 -140.62,-339.44 0,-132.55 53.74,-252.56 140.62,-339.44 86.88,-86.88 206.89,-140.62 339.44,-140.62zm127.31 352.75c-32.56,-32.56 -77.58,-52.71 -127.31,-52.71 -49.73,0 -94.74,20.14 -127.31,52.71 -32.56,32.56 -52.71,77.58 -52.71,127.31 0,49.73 20.14,94.74 52.71,127.31 32.55,32.55 77.58,52.71 127.31,52.71 49.73,0 94.75,-20.15 127.31,-52.71 32.55,-32.55 52.71,-77.58 52.71,-127.31 0,-49.73 -20.15,-94.75 -52.71,-127.31zm-1025.42 376.4l770.21 601.14 0 1524.5 -300.05 0 0 -1379.5 -654.17 -510.57 184.01 -235.58zm1954.17 235.58l-654.17 510.57 0 1379.5 -300.05 0 0 -1524.5 770.21 -601.14 184.01 235.58z"></path>
    </g>
  </svg>
</template>

<script>
export default { name: 'shared-icon' };
</script>
