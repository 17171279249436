<template>
  <v-menu transition="slide-y-transition" offset-y dark>
    <template #activator="{ on }">
      <v-icon v-on="on" dark class="mx-3">mdi-dots-vertical</v-icon>
    </template>
    <v-list dense class="pa-0">
      <template v-if="user">
        <div class="font-weight-bold text-center pa-2">
          {{ user.fullName }}
        </div>
        <v-divider />
        <v-list-item v-if="isAdmin" @click="navigateTo('/admin')">
          <v-icon left>mdi-account-cog</v-icon>
          <v-list-item-title>Admin Dashboard</v-list-item-title>
        </v-list-item>
        <template v-else>
          <v-list-item @click="reset" :to="{ name: 'locations' }" exact>
            <v-icon left>mdi-plus</v-icon>
            <v-list-item-title>Public packages</v-list-item-title>
          </v-list-item>
          <v-list-item @click="reset" :to="{ name: 'packages' }">
            <v-icon left>mdi-package-variant</v-icon>
            <v-list-item-title>Custom packages</v-list-item-title>
          </v-list-item>
          <v-divider />
        </template>
      </template>
      <v-list-item>
        <v-switch
          @change="toggleTheme"
          :input-value="!$vuetify.theme.dark"
          color="primary"
          label="Light mode"
          dense hide-details
          class="mt-0 pt-0" />
      </v-list-item>
      <template v-if="user">
        <v-divider />
        <v-list-item @click="logout">
          <v-icon left>mdi-logout</v-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { navigateTo } from '@/common/navigation';

export default {
  name: 'user-menu',
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['isAdmin'])
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapMutations('bookings', ['reset']),
    navigateTo,
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      const theme = this.$vuetify.theme.dark ? 'dark' : 'light';
      localStorage.setItem('vw_theme', theme);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-input--switch .v-label {
  color: $white !important;
  font-size: 0.8125rem;
  font-weight: 500;
}
</style>
