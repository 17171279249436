<template>
  <div class="carousel-asset">
    <div v-show="isLoading" class="progress-wrapper">
      <v-progress-circular color="primary" size="36" indeterminate />
    </div>
    <video v-if="isVideo" @canplaythrough="isLoading = false" autoplay loop muted>
      <source :src="url">
    </video>
    <v-img v-else @load="isLoading = false" :src="url" />
  </div>
</template>

<script>
const VIDEO_EXTENSIONS = ['.mp4', '.webm'];

export default {
  name: 'carousel-asset',
  props: {
    url: { type: String, default: '' }
  },
  data: () => ({ isLoading: true }),
  computed: {
    isVideo: ({ url }) => VIDEO_EXTENSIONS.some(it => url.includes(it))
  }
};
</script>

<style lang="scss" scoped>
.carousel-asset {
  position: relative;
}

.carousel-asset, ::v-deep .v-image, video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.progress-wrapper {
  position: absolute;
  top: 42%;
  right: 45%;
  z-index: 2;
}
</style>
