<template>
  <div class="crew-selection-container">
    <div class="header">
      <h1 class="text-title">Your Group</h1>
      <h2 class="text-subtitle">
        Select the number of people in your group to see all
        available accommodation types at a price per person.
      </h2>
    </div>
    <div class="sliders">
      <crew-slider
        v-for="{ key, label, hint, retainSlots, max } in sliders"
        :key="key"
        @change="update({ [key]: $event })"
        :value="crew[key]"
        :label="label"
        :hint="hint"
        :max="max || villaSlots"
        :remaining="villaSlots - guests"
        :retain-slots="retainSlots" />
    </div>
    <p class="text-regular total-guests mb-2">
      Selected:
      <span class="primary--text">{{ guests }}</span>
    </p>
    <p class="text-regular">{{ capacityLabel }}</p>
    <proceed-footer @proceed="proceed" :disabled="!adults" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import CrewSlider from './Slider';
import ProceedFooter from '@/main/components/common/ProceedFooter';
import relatedExperienceApi from '@/main/api/relatedExperience';

const SLIDERS = [
  { key: 'ladies', label: 'Lady' },
  { key: 'gentlemen', label: 'Gentleman' },
  { key: 'children', label: 'Child', hint: '3-12y' },
  { key: 'infants', label: 'Infant', hint: '0-2y', max: 10, retainSlots: true }
];

export default {
  name: 'crew-selection',
  data: () => ({
    isDirty: false,
    isChanged: false
  }),
  computed: {
    ...mapState('bookings', ['filter']),
    ...mapGetters('weeks', ['availableSlotsPerWeek']),
    sliders: () => SLIDERS,
    villaSlots: vm => vm.availableSlotsPerWeek(vm.filter.villa),
    isPrivate: vm => vm.filter.roomType === 'PRIVATE',
    crew() {
      const { ladies = 0, gentlemen = 0, children = 0, infants = 0 } = this.filter;
      return { ladies, gentlemen, children, infants };
    },
    adults: ({ crew }) => crew.ladies + crew.gentlemen,
    guests: ({ adults, crew }) => adults + crew.children,
    capacityLabel() {
      const { isPrivate, villaSlots } = this;
      return `${isPrivate ? 'Villa' : 'Max available'} capacity: ${villaSlots}`;
    }
  },
  methods: {
    ...mapMutations('bookings', [
      'updateFilter', 'setDefaultsForStep', 'validateStep', 'invalidateStep',
      'setWeekFormattedExperiences', 'setWeekFormattedAmenities', 'setWeekRelatedExperiences'
    ]),
    update(data) {
      this.isDirty = true;
      this.updateFilter({ ...this.crew, ...data });
      if (this.isPrivate) {
        this.setDefaultsForStep({ step: 5, validateCurrent: false, validateNext: false });
        this.invalidateStep({ step: 6 });
      } else {
        this.setDefaultsForStep({ step: 4, validateNext: false });
        this.invalidateStep({ step: 5 });
      }
    },
    async setRelatedExperiences() {
      const { id: weekId } = this.filter.week;
      const { items } = await relatedExperienceApi.fetch(weekId);
      return this.setWeekRelatedExperiences(items);
    },
    proceed() {
      const name = this.isPrivate ? 'amenities' : 'accommodations';
      this.setWeekFormattedExperiences();
      this.setWeekFormattedAmenities();
      if (!this.isDirty) return this.$router.push({ name });
      this.isPrivate
        ? this.setDefaultsForStep({ step: 5, validateCurrent: false })
        : this.validateStep({ step: 5 });
      this.$router.push({ name });
    }
  },
  created() {
    return this.setRelatedExperiences();
  },
  components: { CrewSlider, ProceedFooter }
};
</script>

<style lang="scss" scoped>
@import '@/main/stylesheets/vuetify';

.sliders {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0 1rem 0;
}

.crew-selection-container {
  display: flex;
  flex-direction: column;

  .text-regular {
    text-align: center;
    letter-spacing: 0;
    color: $light-blue-grey !important;
    font-size: 1.125rem;
  }

  .total-guests {
    margin: 0 0 0.25rem;
    text-transform: uppercase;

    span {
      font-weight: 600;
    }
  }
}
</style>
