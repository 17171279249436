<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="29.9144mm"
    height="38.133mm"
    version="1.1"
    style="shape-rendering: geometricPrecision; text-rendering: geometricPrecision; image-rendering: optimizeQuality; fill-rule: evenodd; clip-rule: evenodd;"
    viewBox="0 0 578.06 736.88"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"></metadata>
      <path class="fil0" d="M401.88 223.78c-8.09,57.79 -59.96,107.66 -128.57,98.31 -57.6,-7.84 -106.71,-61.11 -97.48,-128.89 7.72,-56.74 61.06,-107.14 128.57,-97.32 57.2,8.31 106.9,60.57 97.48,127.9zm-113.4 379.68c3.75,-2.41 11.69,-11.94 15.6,-16.36 5.28,-5.97 9.91,-11.72 15.52,-18.19 61.63,-71.12 153.32,-193.63 184.23,-279.21 14.1,-39.02 17.07,-71.69 6.73,-114.36 -8.55,-35.3 -25.05,-66.85 -43.54,-89.06 -19.78,-23.76 -40.65,-43.01 -72.4,-60.03 -129.45,-69.36 -293.62,4.9 -327.57,149.4 -10.16,43.26 -7.64,74.34 6.62,113.55 11.86,32.61 27.75,61.3 43.92,88.46 37.01,62.16 105.77,153.66 155.27,208.24 4.77,5.26 9.96,12.81 15.61,17.55zm93.52 -83.82l-4.82 7.35c41.08,4.75 82.94,13.66 117.96,28.14 33.2,13.72 77.48,41.92 36.61,76.55 -23.47,19.9 -69.49,32.38 -106.98,39.94 -42.68,8.6 -89.84,11.84 -135.09,11.95 -76.59,0.19 -192.89,-12.38 -242.06,-50.7 -41.91,-32.67 -2.18,-62.9 33.49,-77.23 33.41,-13.43 78.59,-25.06 119.01,-28.3 -4.03,-11.03 -11.57,-7.43 -34.49,-5.35 -48.03,4.37 -136.49,16.44 -159.6,60.94 -13.17,25.36 -2.64,51.27 10.97,67.32 48.25,56.88 166.03,85.36 266.65,86.59 83.98,1.03 211.06,-18.98 272.16,-81.12 36.3,-36.91 28.23,-83.49 -24.74,-107.61 -36.52,-16.63 -103.58,-27.46 -149.08,-28.47z"></path>
    </g>
  </svg>
</template>

<script>
export default { name: 'location-icon' };
</script>
