<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="8.0778mm"
    height="16.9425mm"
    version="1.1"
    style="shape-rendering: geometricPrecision; text-rendering: geometricPrecision; image-rendering: optimizeQuality; fill-rule: evenodd; clip-rule: evenodd;"
    viewBox="0 0 151.77 318.32"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"></metadata>
      <path class="fil0" d="M-0 124.1l0 75.88c0,18.4 25.6,20.95 25.68,0.05l-0.05 -54.26c0,-4.04 -0.34,-9.91 0.07,-13.72 0.58,-5.33 1.93,-5.88 5.84,-7.96 8.76,2.04 6.9,9.47 6.9,19.71l0.08 161.54c2.22,16.68 31.03,18.76 31.5,-3.81l-0.05 -67.06c0,-14.32 -3.65,-35.99 11.83,-25.62 0,10.66 -0.68,87.46 0.36,94.25 3.14,20.41 26.91,18.61 31.38,4.51 2.86,-9.01 -1.74,-166.5 1.76,-181.54 8.3,-1.93 6.1,-1.2 10.84,1.97 0,16.76 0,33.51 0,50.26 0,16.81 -3.18,36.46 13.8,36.46 5.92,0 11.83,-5.91 11.83,-11.83l0 -82.78c0,-11.2 -6.16,-19.62 -13.23,-24.22 -7.74,-5.05 -18.8,-6.33 -31.12,-6.33l-64.06 0c-24.04,0 -43.36,7.63 -43.36,34.49zm35.48 -82.78c0,9.23 3.78,18.5 9.12,25.38 3.65,4.7 9.42,9.23 14.84,11.77 11.06,5.18 22.21,5.38 33.52,0.62 25.3,-10.64 34.19,-45.24 13.25,-66.11 -28.89,-28.78 -70.72,-5.4 -70.72,28.34z">
      </path>
    </g>
  </svg>
</template>

<script>
export default { name: 'boy-icon' };
</script>
