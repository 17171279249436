<template>
  <div class="amenity-list-preview">
    <v-expansion-panels v-model="expanded" accordion>
      <v-expansion-panel v-for="amenity in amenities" :key="amenity.id">
        <v-expansion-panel-header>
          <div class="d-flex align-center">
            <div class="header-left">
              <span class="name">{{ amenity.name }}</span>
              <v-chip x-small class="readonly ml-2 mr-3">
                <p class="chip-text">{{ amenity.category.name }}</p>
              </v-chip>
            </div>
            <span class="text-regular selected-slots">
              {{ amenity.selectedSlots }}
            </span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <amenity-carousel
            @close="expanded = false"
            v-bind="{ ...amenity, isDialogAmenity }" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import AmenityCarousel from './Carousel';
import { mapGetters } from 'vuex';
import orderBy from 'lodash/orderBy';

export default {
  name: 'included-amenities-list',
  props: {
    selectedAmenities: { type: Array, default: () => [] },
    isDialogAmenity: { type: Boolean, default: false }
  },
  data: () => ({ expanded: null }),
  computed: {
    ...mapGetters('bookings', ['includedAmenities']),
    amenities() {
      const { selectedAmenities, includedAmenities } = this;
      const amenities = selectedAmenities.length ? selectedAmenities : includedAmenities;
      return orderBy(amenities, 'name');
    }
  },
  components: { AmenityCarousel }
};
</script>

<style lang="scss" scoped>
@import '@/main/stylesheets/vuetify';

.name {
  width: 53%;
}

.header-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

::v-deep .v-expansion-panels .v-expansion-panel {
  .v-expansion-panel-header .selected-slots {
    margin: 0 0.375rem 0 auto;
    font-size: 1.125rem;
  }

  &:first-child .v-carousel {
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
  }

  &:last-child .v-carousel {
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
}
</style>
