<template>
  <div class="locations-container">
    <div v-if="isLoading" class="progress-container">
      <v-progress-circular size="60" color="#cfd8dc" indeterminate />
    </div>
    <template v-else>
      <div class="header">
        <h1 class="text-title">Destination</h1>
        <h2 class="text-subtitle">
          Choose your desired destination to begin this journey.
        </h2>
      </div>
      <div class="locations">
        <location-card
          v-for="location in locations"
          :key="location.id"
          :location="location" />
      </div>
    </template>
    <proceed-footer
      v-if="hasSelection"
      @proceed="$router.push({ name: 'week-type' })" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import LocationCard from './Card';
import orderBy from 'lodash/orderBy';
import ProceedFooter from '@/main/components/common/ProceedFooter';

export default {
  name: 'locations',
  computed: {
    ...mapState(['isLoading']),
    ...mapState('bookings', ['filter']),
    ...mapState('locations', ['items']),
    locations: vm => orderBy(vm.items, it => it.weeks.length, ['desc']),
    hasSelection: vm => !!vm.filter.location
  },
  methods: {
    ...mapActions('locations', ['fetch']),
    ...mapMutations(['setLoading'])
  },
  beforeRouteEnter(_to, _from, next) {
    next(vm => vm.setLoading());
  },
  created() {
    this.fetch();
  },
  components: { LocationCard, ProceedFooter }
};
</script>

<style lang="scss" scoped>
.locations-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .locations {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 1rem -1rem;

    .v-card {
      max-width: calc(100% - 2rem);
    }
  }
}
</style>
