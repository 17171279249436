<template>
  <div class="confirm-actions-container">
    <v-btn
      v-if="!isValid"
      @click="$emit('validate')"
      color="cyan"
      outlined rounded
      class="booking-button">
      SUBMIT
    </v-btn>
    <template v-else>
      <div class="label is-size-4">{{ format(totalAmount) }}</div>
      <v-icon class="stripe-icon">$stripe</v-icon>
      <stripe
        @paid="save($event, provider.STRIPE)"
        :amount="totalAmount"
        :name="name" />
      <v-icon class="coinbase-icon">$coinbase</v-icon>
      <coin-base @paid="save($event, provider.COINBASE)" :amount="totalAmount" />
      <template v-if="!disablePaypal">
        <v-icon class="paypal-icon">$paypal</v-icon>
        <pay-pal
          @payment-authorized="save($event, provider.PAYPAL)"
          :amount="`${totalAmount}`"
          :button-style="{ size: 'medium', shape: 'rect' }"
          :client="credentials"
          currency="EUR"
          env="production"
          class="paypal-btn" />
      </template>
    </template>
  </div>
</template>

<script>
import CoinBase from './common/Coinbase';
import { PaymentProvider } from '@/../common/config';
import PayPal from 'vue-paypal-checkout';
import { PAYPAL_LIMIT } from '@/main/common/config';
import Stripe from './common/Stripe';

export default {
  name: 'confirm-booking-actions',
  props: {
    format: { type: Function, required: true },
    isValid: { type: Boolean, default: false },
    totalAmount: { type: Number, required: true },
    email: { type: String, default: '' },
    name: { type: String, default: '' }
  },
  computed: {
    provider: () => PaymentProvider,
    disablePaypal: vm => vm.totalAmount > PAYPAL_LIMIT,
    credentials: () => ({ sandbox: '', production: process.env.PAYPAL_CLIENT_ID })
  },
  methods: {
    save(data, provider) {
      this.$emit('save', { ...data, provider });
    }
  },
  components: { CoinBase, PayPal, Stripe }
};
</script>

<style lang="scss" scoped>
@import '@/main/stylesheets/payment';

.confirm-actions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.booking-button {
  width: 35% !important;
  height: 2.5rem !important;
  letter-spacing: 0.0625rem;

  @media screen and (max-width: 48rem) {
    width: 100% !important;
  }
}

::v-deep .paypal-btn .zoid-outlet {
  z-index: 0;
}
</style>
