<template>
  <router-host>
    <v-app id="app">
      <router-view />
    </v-app>
  </router-host>
</template>

<script>
import RouterHost from '@/common/components/RouterHost';

export default {
  name: 'app',
  components: { RouterHost }
};
</script>

<style lang="scss">
@import '@/main/stylesheets/main';

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  font-family: $font-family-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.theme--dark {
    background: $dark;
  }

  a {
    color: $secondary;
  }
}
</style>
