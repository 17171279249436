import auth from '@/common/store/modules/auth';
import bookings from './bookings';
import locations from './locations';
import settings from './settings';
import Vue from 'vue';
import Vuex from 'vuex';
import weeks from './weeks';

Vue.use(Vuex);

const isProduction = process.env.NODE_ENV === 'production';

const store = new Vuex.Store({
  state: {
    isLoading: true
  },
  mutations: {
    setLoading: (state, value = true) => (state.isLoading = value)
  },
  modules: {
    auth,
    bookings,
    locations,
    settings,
    weeks
  },
  strict: !isProduction
});

export default function getStore() {
  return store.dispatch('auth/fetchUserInfo').then(() => store);
}
