<template>
  <v-footer height="64" fixed>
    <div class="content">
      <v-slider ref="slider" v-bind="progressBarOptions">
        <template #thumb-label="{ value }">
          <p class="text-regular ma-0 pt-2">{{ value }}PAX</p>
        </template>
      </v-slider>
      <p class="text-regular summary">{{ selectionSummary }}</p>
    </div>
    <v-btn
      @click="navigateTo"
      :disabled="!canAdvance"
      color="cyan"
      depressed rounded>
      Next
    </v-btn>
  </v-footer>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { formatAvailability } from '@/main/common/utils';

export default {
  name: 'accommodation-footer',
  props: {
    rooms: { type: Array, default: () => [] }
  },
  computed: {
    ...mapState('bookings', ['filter']),
    ...mapGetters('bookings', ['guests', 'units', 'accommodationSlots']),
    ...mapGetters('weeks', ['availableSlotsPerWeek']),
    maxCapacity: vm => vm.availableSlotsPerWeek(vm.filter.villa),
    progressBarOptions: vm => ({
      value: vm.accommodationSlots,
      max: vm.maxCapacity,
      trackColor: 'grey darken-1',
      color: 'primary',
      height: 28,
      thumbSize: 15,
      thumbLabel: 'always',
      hideDetails: true,
      readonly: true
    }),
    canAdvance() {
      const hasSelections = this.guests && this.units;
      const hasEnoughBeds = this.accommodationSlots >= this.guests;
      return hasSelections && hasEnoughBeds;
    },
    selectionSummary() {
      const { rooms } = this;
      return `${formatAvailability(rooms.length, 'bedroom')} selected`;
    }
  },
  methods: {
    ...mapMutations('bookings', ['validateStep']),
    navigateTo() {
      this.validateStep({ step: 5 });
      return this.$router.push({ name: 'amenities' });
    }
  },
  mounted() {
    const threshold = 100 * (this.guests / this.maxCapacity);
    return this.$refs.slider.$el.style.setProperty('--left', `${threshold}%`);
  }
};
</script>

<style lang="scss" scoped>
.v-footer.v-sheet {
  flex-flow: row nowrap;

  .text-regular {
    color: $light-blue-grey;
  }

  .v-btn {
    min-width: 5rem !important;
  }
}

::v-deep .v-input__slider {
  --left: "100%";

  .v-slider::before {
    content: "";
    position: absolute;
    top: calc(50% - 0.25rem);
    left: var(--left);
    width: 0.125rem;
    height: 0.5rem;
    background: #757575;
  }
}

::v-deep {
  .v-input__slider .v-input__control .v-input__slot .v-slider {
    margin: 0 0.875rem;

    .v-slider__thumb {
      border-radius: 0;
      left: 0;
      width: 0.125rem;
      height: 0.5rem;

      &::before {
        content: none;
      }
    }

    .v-slider__thumb-label {
      width: 3.125rem !important;
      height: 0.375rem !important;
      background-color: transparent !important;
    }
  }

  .content {
    flex: 1 auto;
    margin: 0 0.875rem 0 0;
  }

  .content .summary {
    display: block;
    margin: 0 0 0 0.75rem;
    text-align: start;

    @media screen and (max-width: 56.25rem) {
      font-size: 0.75rem;
    }
  }
}
</style>
