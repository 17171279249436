import { extractData } from '@/common/api/helpers';
import request from '@/common/api/request';

const urls = {
  root: '/stripe-sessions'
};

function create(params) {
  return request.post(urls.root, params).then(extractData);
}

export default { create };
