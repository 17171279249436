<template>
  <div class="section-container">
    <div class="data-row">
      <div class="info-box">
        <v-icon>$girl</v-icon>
        <span class="text-regular">Ladies</span>
        <span class="text-regular people-number">{{ ladies || 0 }}</span>
      </div>
      <div class="info-box">
        <v-icon>$boy</v-icon>
        <span class="text-regular">Gentlemen</span>
        <span class="text-regular people-number">{{ gentlemen || 0 }}</span>
      </div>
      <div class="info-box">
        <v-icon>mdi-human-child</v-icon>
        <span class="text-regular">Children</span>
        <span class="text-regular people-number">{{ children || 0 }}</span>
      </div>
      <div class="info-box">
        <v-icon>mdi-baby</v-icon>
        <span class="text-regular">Infants</span>
        <span class="text-regular people-number">{{ infants || 0 }}</span>
      </div>
    </div>
    <template v-if="filter.week">
      <div v-if="isPrivateAccomodation" class="data-row">
        <div class="info-box">
          <v-icon>$accommodation</v-icon>
          <span class="text-regular">Entire Villa</span>
        </div>
      </div>
      <div class="data-row">
        <div class="info-box">
          <v-icon>mdi-bed</v-icon>
          <span class="text-regular text-uppercase">
            Bedrooms
            <b>{{ rooms.length }}</b>
          </span>
        </div>
        <div class="info-box">
          <v-icon>mdi-shower-head</v-icon>
          <span class="text-regular text-uppercase">
            Bathrooms
            <b>{{ bathrooms }}</b>
          </span>
        </div>
      </div>
      <v-chip-group column>
        <v-chip
          v-for="{ name, count, color } in comforts"
          :key="name"
          :color="color"
          :class="`readonly ${kebabCase(name)}`"
          small
          class="ml-0 mt-1">
          <span class="mr-1">{{ count }}</span>
          <span class="text-capitalize">{{ name }}</span>
        </v-chip>
      </v-chip-group>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { COMFORT_COLORS } from '@/main/common/config';
import countBy from 'lodash/countBy';
import kebabCase from 'lodash/kebabCase';
import map from 'lodash/map';

export default {
  name: 'general-info',
  props: {
    ladies: { type: Number, default: null },
    gentlemen: { type: Number, default: null },
    children: { type: Number, default: null },
    infants: { type: Number, default: null }
  },
  computed: {
    ...mapState('bookings', ['filter', 'rooms']),
    ...mapGetters('bookings', ['bathrooms']),
    isPrivateAccomodation: vm => vm.filter.roomType === 'PRIVATE',
    comforts() {
      const comforts = countBy(this.rooms, 'comfort');
      return map(comforts, (count, name) => ({
        count, name, color: COMFORT_COLORS[name]
      }));
    }
  },
  methods: {
    kebabCase
  }
};
</script>

<style lang="scss" scoped>
.section-container {
  padding: 0 1rem 0.875rem !important;

  .text-regular {
    font-size: 1rem;

    @media screen and (max-width: 56.25rem) {
      font-size: 0.875rem;
    }
  }
}

.data-row {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: 0.75rem 0 0.75rem -0.375rem;
  font-size: 1rem;

  .people-number {
    padding-left: 0.75rem;
    font-weight: 600;
  }

  .info-box {
    display: flex;
    align-items: center;
    margin-right: 1.5rem;

    .v-icon {
      margin-right: 0.75rem;
      color: $primary;
      font-size: 1rem;
    }
  }
}

.v-icon ::v-deep svg {
  width: 1rem;
  height: 1rem;
  fill: $primary;
}

::v-deep .v-item-group.v-chip-group {
  margin-left: -0.375rem;

  .v-slide-group__content {
    justify-content: flex-start;
    padding: 0 0 0.5rem;
  }
}

::v-deep .v-chip {
  margin: 0 0.25rem;

  .v-chip__content {
    margin: 0;
    color: $white;
    font-weight: 600;
    letter-spacing: 0.0625rem;
  }

  &.economy .v-chip__content {
    color: $black !important;
  }
}
</style>
