import icons from '@/main/components/common/icons';
import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

const COLORS = () => ({
  primary: '#f8c94e',
  secondary: '#00bcd4',
  dark: '#151515',
  danger: '#d94565'
});

export default new Vuetify({
  icons: {
    values: {
      accommodation: {
        component: icons.Accommodation
      },
      boy: {
        component: icons.Boy
      },
      calendar: {
        component: icons.Calendar
      },
      checked: {
        component: icons.Checked
      },
      coinbase: {
        component: icons.Coinbase
      },
      finished: {
        component: icons.Finished
      },
      girl: {
        component: icons.Girl
      },
      info: {
        component: icons.Info
      },
      location: {
        component: icons.Location
      },
      lock: {
        component: icons.Lock
      },
      logo: {
        component: icons.Logo
      },
      logoOneColor: {
        component: icons.LogoOneColor
      },
      paypal: {
        component: icons.Paypal
      },
      stripe: {
        component: icons.Stripe
      },
      shared: {
        component: icons.Shared
      },
      VWLogo: {
        component: icons.VWLogo
      }
    }
  },
  theme: {
    options: {
      customProperties: true
    },
    dark: true,
    themes: {
      dark: COLORS(),
      light: COLORS()
    }
  }
});
