import { DISCOUNT, PREMIUM } from '@/main/common/config';
import flatMap from 'lodash/flatMap';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';

export const isPackage = ({ filter }) => !!filter.outsidePackage;

export const accommodationSlots = ({ rooms }) => sumBy(rooms, 'slots');
export const bathrooms = ({ rooms }) => sumBy(rooms, 'bathrooms');
export const units = ({ rooms }) => rooms.length;

export const price = (_, getters) => {
  const { accommodationPrice, experiencesPrice, amenitiesPrice } = getters;
  return accommodationPrice + experiencesPrice + amenitiesPrice;
};

export const isPremiumRate = ({ filter }, { isFullVillaCapacitySelected }) => {
  const { week = {}, roomType } = filter;
  const isPrivate = roomType === 'PRIVATE';
  return week.isShared && (isPrivate || isFullVillaCapacitySelected);
};

export const pricePerPerson = (_, { guests, price }) => {
  if (!guests) return 0;
  return !guests || !price ? 0 : price / guests;
};

export const getActivityPrice = (_, getters) => activity => {
  const { selectedSlots, group, isAmenity } = activity;
  const {
    isPackage,
    isExpFullPackageSelected,
    isAmenityFullPackageSelected,
    isPremiumRate
  } = getters;
  const pricePerPerson = group ? activity.price / selectedSlots : activity.price;
  const premiumPrice = pricePerPerson + (pricePerPerson * PREMIUM);
  const price = isPremiumRate ? premiumPrice : pricePerPerson;
  const discountPrice = price - (price * DISCOUNT);
  const isFullPackageSelected = isAmenity ? isAmenityFullPackageSelected : isExpFullPackageSelected;
  return !isPackage && isFullPackageSelected ? discountPrice : price;
};

export const accommodationPrice = ({ filter }, getters) => {
  const { isPremiumRate, accommodationSlots } = getters;
  const pricePerPerson = filter.week?.price || 0;
  const price = pricePerPerson * accommodationSlots;
  const premiumPrice = price + (price * PREMIUM);
  return isPremiumRate ? premiumPrice : price;
};

export const experiencesPrice = ({ experiences }, getters) => {
  const { isPackage, isExpFullPackageSelected, isPremiumRate } = getters;
  const price = sumItems(experiences, isPremiumRate);
  const discountPrice = price - (price * DISCOUNT);
  return !isPackage && isExpFullPackageSelected ? discountPrice : price;
};

export const amenitiesPrice = ({ amenities }, getters) => {
  const { isPackage, isAmenityFullPackageSelected, isPremiumRate } = getters;
  const price = sumItems(amenities, isPremiumRate);
  const discountPrice = price - (price * DISCOUNT);
  return !isPackage && isAmenityFullPackageSelected ? discountPrice : price;
};

export const expFullPackagePrice = (_, getters) => {
  const { optionalExperiences, isPremiumRate } = getters;
  return sumItems(optionalExperiences, isPremiumRate);
};

export const amenityFullPackagePrice = (_, getters) => {
  const { optionalAmenities, isPremiumRate } = getters;
  return sumItems(optionalAmenities, isPremiumRate);
};

export const isExpFullPackageSelected = (state, { optionalExperiences, guests }) => {
  const options = { optionalItems: optionalExperiences, guests };
  return fullPackageSelected(state.experiences, options);
};

export const isAmenityFullPackageSelected = (state, { optionalAmenities, guests }) => {
  const options = { optionalItems: optionalAmenities, guests };
  return fullPackageSelected(state.amenities, options);
};

export const selectedExperienceIds = state => flatMap(state.experiences, 'id');

export const selectedAmenityIds = state => flatMap(state.amenities, 'id');

export const guests = ({ filter }) => filter.ladies + filter.gentlemen + filter.children;

export const isFullVillaCapacitySelected = ({ filter, rooms }) => {
  const { villa } = filter;
  if (!villa) return false;
  return villa.rooms.every(it => map(rooms, 'id').includes(it.id));
};

export const optionalExperiences = state => {
  return state.weekFormattedExperiences.filter(it => !it.included);
};

export const includedExperiences = state => {
  return state.weekFormattedExperiences.filter(it => it.included);
};

export const optionalAmenities = state => {
  return state.weekFormattedAmenities.filter(it => !it.included);
};

export const includedAmenities = state => {
  return state.weekFormattedAmenities.filter(it => it.included);
};

function fullPackageSelected(items, { optionalItems, guests }) {
  const availableOptionalItems = optionalItems.filter(it => !it.soldOut);
  if (!items.length) return false;
  const hasAllItems = availableOptionalItems.length === items.length;
  return items.every(it => {
    const allSlots = it.selectedSlots >= guests;
    const maxAvailableSlots = it.selectedSlots === it.availableSlots;
    return (allSlots || maxAvailableSlots) && hasAllItems;
  });
}

function sumItems(items, isPremium) {
  return sumBy(items, ({ group, price, selectedSlots }) => {
    const basePrice = group ? price : price * selectedSlots;
    const premiumPrice = basePrice + (basePrice * PREMIUM);
    return isPremium ? premiumPrice : basePrice;
  });
}
