<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div class="included" v-html="included"></div>
</template>

<script>
export default {
  name: 'included',
  props: {
    included: { type: String, default: '' }
  }
};
</script>

<style lang="scss" scoped>
.included ::v-deep {
  div {
    margin-bottom: 1rem;
    font-size: 0.875rem;
    text-align: left;
  }

  ul, ol {
    margin: 1rem 0.5rem;

    p {
      margin-bottom: 0;
    }

    @media screen and (max-width: 56.25rem) {
      font-size: 0.9375rem;
    }
  }
}
</style>
