<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="50mm"
    height="50mm"
    version="1.1"
    style="shape-rendering: geometricPrecision; text-rendering: geometricPrecision; image-rendering: optimizeQuality; fill-rule: evenodd; clip-rule: evenodd;"
    viewBox="0 0 1897.55 1897.55"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
    <linearGradient
      id="id0"
      gradientUnits="userSpaceOnUse"
      x1="948.78"
      y1="1897.55"
      x2="948.78"
      y2="-0">
      <stop offset="0" style="stop-opacity: 1; stop-color: #dfd50a;"></stop>
      <stop offset="1" style="stop-opacity: 1; stop-color: #f27334;"></stop>
    </linearGradient>
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"></metadata>
      <path class="fil0" d="M1010.94 1789.85l0.02 0 -62.2 107.7 -243.44 -421.67 -131.79 228.31 -15.03 25.95 -37.81 65.53c-308.91,-156.46 -520.69,-476.93 -520.69,-846.9 0,-215.05 71.61,-413.37 192.22,-572.49l474.72 822.27 -60.88 105.42 -416.86 -722.07c-53.67,110.93 -83.78,235.39 -83.78,366.87 -0.01,293.27 149.73,551.5 376.86,702.6l12.36 -21.47 354.87 -614.64 60.88 105.41 -144.21 249.78 182.58 316.26 756.56 -1310.43c120.63,159.11 192.22,357.44 192.22,572.49 -0.01,369.97 -211.75,690.42 -520.67,846.88l-184.64 -319.79 -181.3 313.98zm82.05 -485.88l-663.53 -1149.27c149.2,-97.79 327.6,-154.69 519.32,-154.69 191.72,0 370.12,56.9 519.31,154.69l-458.44 794.08 144.21 249.78 -60.87 105.42zm231.07 -1110.62l-375.29 650.01 -375.28 -650.02c113.03,-56.26 240.46,-87.92 375.29,-87.92 134.83,-0.01 262.26,31.66 375.28,87.93zm91.24 1458.02c227.13,-151.1 376.84,-409.34 376.84,-702.6 -0.01,-131.49 -30.09,-255.95 -83.78,-366.87l-455.26 788.55 162.2 280.92 0 0z"></path>
    </g>
  </svg>
</template>

<script>
export default { name: 'logo-icon' };
</script>
