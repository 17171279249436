<template>
  <div class="experiences-list-preview">
    <v-expansion-panels v-model="expanded" accordion>
      <v-expansion-panel v-for="exp in experiences" :key="exp.id">
        <v-expansion-panel-header>
          <div class="d-flex align-center">
            <div class="header-left">
              <span class="name">{{ exp.name }}</span>
              <v-chip x-small class="readonly ml-2 mr-3">
                <p class="chip-text">{{ exp.category.name }}</p>
              </v-chip>
            </div>
            <span class="text-regular selected-slots">
              {{ exp.selectedSlots }}
            </span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <experience-carousel
            @close="expanded = false"
            v-bind="{ ...exp, isDialogExperience }" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import ExperienceCarousel from './Carousel';
import flatMap from 'lodash/flatMap';
import { mapGetters } from 'vuex';
import orderBy from 'lodash/orderBy';

export default {
  name: 'included-experiences-list',
  props: {
    selectedExperiences: { type: Array, default: () => [] },
    isDialogExperience: { type: Boolean, default: false }
  },
  data: () => ({ expanded: null }),
  computed: {
    ...mapGetters('bookings', ['includedExperiences']),
    experiences() {
      const { selectedExperiences, includedExperiences } = this;
      const exps = selectedExperiences.length ? selectedExperiences : includedExperiences;
      const childExps = flatMap(exps, it => it.childExperiences || []);
      return orderBy([...exps, ...childExps], 'name');
    }
  },
  components: { ExperienceCarousel }
};
</script>

<style lang="scss" scoped>
@import '@/main/stylesheets/vuetify';

.name {
  width: 53%;
}

.header-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

::v-deep .v-expansion-panels .v-expansion-panel {
  .v-expansion-panel-header .selected-slots {
    margin: 0 0.375rem 0 auto;
    font-size: 1.125rem;
  }

  &:first-child .v-carousel {
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
  }

  &:last-child .v-carousel {
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
}
</style>
