import generateActions from '@/common/store/helpers/actions';
import partition from 'lodash/partition';
import sumBy from 'lodash/sumBy';
import transform from 'lodash/transform';

const { api, get, reset, save, remove } = generateActions('/weeks');

const fetch = async ({ commit }, params) => {
  const items = await api.fetch(params);
  commit('reset', processWeeks(items));
  commit('setLoading', false, { root: true });
};

export { fetch, get, reset, save, remove };

function processWeeks(items = {}) {
  return transform(items, (acc, week, key) => {
    const { rooms } = week.villa;
    const weekActivities = processExperiences(week.experiences);
    const [amenities, experiences] = partition(weekActivities, 'isAmenity');
    const villa = { slots: sumBy(rooms, 'slots'), rooms };
    Object.assign(week.villa, villa);
    acc[key] = { ...week, amenities, experiences };
  });
}

function processExperiences(experiences) {
  return experiences.map(({ weekExperience, bookings, ...experience }) => {
    const { weekId, slots, included, price, group } = weekExperience;
    const taken = sumBy(bookings, it => {
      return it.weekId === weekId && it.bookingExperience.slots;
    });
    return { ...experience, included, price, group, slots, taken };
  });
}
