<template>
  <v-snackbar v-on="$listeners" v-bind="$attrs" :timeout="timeout" multi-line>
    {{ message }}
    <template #action="{ attrs }">
      <v-btn @click="close" v-bind="attrs" text>Dismiss</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'vw-snackbar',
  props: {
    message: { type: String, default: '' },
    timeout: { type: Number, default: 2500 },
    action: { type: Function, default: () => null }
  },
  methods: {
    close() {
      this.action();
      this.$emit('input', false);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-snack {
  position: fixed;
  z-index: 2;

  &.v-snack--bottom {
    padding-bottom: 3.5rem !important;
  }

  &.v-snack--top, &.v-snack--left, &.v-snack--right {
    padding-top: 4rem !important;

    @media screen and (min-width: 48rem) {
      padding-top: 4rem !important;
    }
  }

  @media screen and (max-width: 48rem) {
    .v-snack__content {
      padding: 0.875rem 1.75rem;
      font-size: 0.75rem;
    }
  }

  @media screen and (min-width: 48rem) {
    padding-top: 11.75rem !important;
  }
}
</style>
