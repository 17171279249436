<template>
  <div class="addon-experiences-container">
    <h3 class="sub-exp-heading">Recommendation to expand the experience</h3>
    <div
      v-for="exp in experiences"
      :key="exp.id"
      class="experience mb-1">
      <div class="information">
        <h2 class="exp-name">{{ exp.name }}</h2>
        <v-chip x-small class="readonly ml-2 mr-0">
          <p class="chip-text">{{ exp.category.name }}</p>
        </v-chip>
        <v-icon
          @click="$emit('reveal-experience', exp)"
          color="cyan"
          class="external-link-icon mr-1">
          mdi-open-in-new
        </v-icon>
      </div>
    </div>
    <v-divider class="mt-2 mb-0 mx-3" />
  </div>
</template>

<script>
export default {
  props: {
    experiences: { type: Array, default: () => [] }
  }
};
</script>
