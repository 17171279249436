<template>
  <v-card :class="{ selected: isSelected }">
    <v-carousel
      v-model="activeSlide"
      prev-icon="mdi-chevron-left"
      next-icon="mdi-chevron-right"
      :show-arrows="assets.length > 1"
      hide-delimiters>
      <v-carousel-item
        v-for="({ url }, index) in assets"
        :key="index"
        reverse-transition="fade"
        transition="fade"
        eager>
        <carousel-asset v-if="activeSlide === index" :url="url" />
      </v-carousel-item>
      <div class="carousel-content">
        <slot name="body"></slot>
      </div>
    </v-carousel>
    <v-card-actions>
      <v-btn
        v-on="$listeners"
        :disabled="!hasAvailableCapacity"
        :class="{ 'is-booked': isBooked }"
        depressed block tile>
        <v-icon v-if="isSelected" color="black" left>mdi-check</v-icon>
        {{ buttonActionText }}
      </v-btn>
    </v-card-actions>
    <div v-if="$slots.footer && hasAvailableCapacity" class="footer">
      <slot name="footer"></slot>
    </div>
  </v-card>
</template>

<script>
import CarouselAsset from './CarouselAsset';

export default {
  name: 'vw-card',
  props: {
    hasAvailableCapacity: { type: [Boolean, Number], default: false },
    isBooked: { type: Boolean, default: false },
    isSelected: { type: Boolean, default: false },
    assets: { type: Array, required: true }
  },
  data: () => ({ activeSlide: 0 }),
  computed: {
    buttonActionText() {
      const { hasAvailableCapacity, isSelected, isBooked } = this;
      if (isBooked) return 'Booked';
      if (!hasAvailableCapacity) {
        return this.$route.name === 'locations'
          ? 'Coming soon'
          : 'Booked';
      }
      return isSelected ? 'Selected' : 'Select';
    }
  },
  components: { CarouselAsset }
};
</script>

<style lang="scss" scoped>
@import '@/main/stylesheets/card';
</style>
