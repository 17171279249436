import { extractData } from '@/common/api/helpers';
import join from 'url-join';
import request from '@/common/api/request';

function fetch(weekId, opts = {}) {
  const endpoint = join('weeks', `${weekId}`, 'related-experiences');
  return request.get(endpoint, opts)
    .then(extractData);
}

export default {
  fetch
};
