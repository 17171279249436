<template>
  <div class="room-type-container">
    <div class="header">
      <h1 class="text-title">Type</h1>
      <h2 class="text-subtitle">
        Choose the type of your ultimate journey.
      </h2>
    </div>
    <div class="room-types">
      <room-type-card
        v-for="choice in roomTypes"
        :key="choice.name"
        v-bind="choice" />
    </div>
    <proceed-footer
      v-if="hasSelection"
      @proceed="$router.push({ name: 'bookable-week' })" />
  </div>
</template>

<script>
import { IMG_URLS } from '@/main/common/config';
import { mapState } from 'vuex';
import ProceedFooter from '@/main/components/common/ProceedFooter';
import RoomTypeCard from './Card';

const roomTypes = () => [{
  type: 'PRIVATE',
  assets: [{ url: IMG_URLS.private }],
  name: 'Private',
  tagline: 'Bring your own group friends and go fully private'
}, {
  type: 'SHARED',
  assets: [{ url: IMG_URLS.shared }],
  name: 'Shared',
  tagline: 'Go with friends or alone and meet new people'
}];

export default {
  name: 'room-type',
  computed: {
    ...mapState('bookings', ['filter']),
    hasSelection: vm => !!vm.filter.roomType,
    roomTypes
  },
  components: { RoomTypeCard, ProceedFooter }
};
</script>

<style lang="scss" scoped>
.room-type-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .room-types {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 1rem -1rem;

    .v-card {
      max-width: calc(100% - 2rem);
    }
  }
}
</style>
