import map from 'lodash/map';
import min from 'lodash/min';
import pluralize from 'pluralize';

export const currencyFormatter = ({ digits = 2 } = {}) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: digits
  });
};

export const formatAvailability = (itemsLength, name) => {
  return `${itemsLength} ${pluralize(name, itemsLength)}`;
};

export const adjustPrice = ({ isShared, price }, modifier = 0) => {
  return modifier && isShared ? price + (price * modifier) : price;
};

export const getMinPrice = (items, modifier = 0) => {
  const prices = map(items, item => adjustPrice(item, modifier));
  return currencyFormatter({ digits: 0 }).format(min(prices));
};
