import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const state = {
  items: {},
  filter: {},
  rooms: [],
  experiences: [],
  weekFormattedExperiences: [],
  amenities: [],
  weekFormattedAmenities: [],
  oneGuestWarningSeen: false,
  stepValidation: new Array(9).fill(false)
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
