<template>
  <vw-card
    @click="setWeekTypeAndProceed"
    v-bind="{ assets, hasAvailableCapacity, isSelected }">
    <template #body>
      <v-icon :class="type.toLowerCase()">$logo</v-icon>
      <p class="text-title">{{ name }}</p>
      <p class="text-subtitle">{{ tagline }}</p>
      <p class="text-subtitle">{{ description }}</p>
      <template v-if="hasAvailableCapacity">
        <p class="text-regular my-1">Available</p>
        <p class="text-regular">{{ showAvailableVillas }}</p>
      </template>
    </template>
    <template #footer>
      <p class="text-regular">from {{ getMinPrice }} per person</p>
    </template>
  </vw-card>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { getMinPrice } from '@/main/common/utils';

export default {
  name: 'week-type-card',
  props: {
    name: { type: String, required: true },
    type: { type: String, required: true },
    assets: { type: Array, required: true },
    tagline: { type: String, required: true },
    description: { type: String, required: true }
  },
  computed: {
    ...mapState('bookings', ['filter']),
    ...mapGetters('weeks', [
      'fullWeeks', 'weekends', 'privateWeekVillas', 'privateWeekendVillas'
    ]),
    isSelected: vm => vm.filter.weekType === vm.type,
    hasAvailableCapacity() {
      const { type, fullWeeks, weekends } = this;
      return type === 'WEEK' ? fullWeeks.length : weekends.length;
    },
    getMinPrice() {
      const { type, fullWeeks, weekends } = this;
      const weeks = type === 'WEEK' ? fullWeeks : weekends;
      return getMinPrice(weeks);
    },
    weekVillas: vm => ({
      private: vm.privateWeekVillas.length,
      shared: vm.fullWeeks.length
    }),
    weekendVillas: vm => ({
      private: vm.privateWeekendVillas.length,
      shared: vm.weekends.length
    }),
    showAvailableVillas() {
      const { type, weekVillas, weekendVillas } = this;
      const villas = type === 'WEEK' ? weekVillas : weekendVillas;
      return `${villas.shared} Shared | ${villas.private} Private`;
    }
  },
  methods: {
    ...mapMutations('bookings', ['updateFilter', 'setDefaultsForStep']),
    setWeekTypeAndProceed() {
      const { type: weekType, isSelected } = this;
      if (isSelected) return this.$router.push({ name: 'room-type' });
      this.setDefaultsForStep({ step: 1 });
      this.updateFilter({ weekType });
      this.$router.push({ name: 'room-type' });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep svg {
  width: 2rem;
  height: 2rem;
  margin-bottom: 1rem;
}

.v-icon.week {
  fill: url(#id0);
}

.v-icon.weekend {
  fill: #66bb6a;
}
</style>
