<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="18.9432mm"
    height="18.9384mm"
    version="1.1"
    style="shape-rendering: geometricPrecision; text-rendering: geometricPrecision; image-rendering: optimizeQuality; fill-rule: evenodd; clip-rule: evenodd;"
    viewBox="0 0 211.51 211.46"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"></metadata>
      <path class="fil0" d="M93.88 117.02c0.94,-0.42 1.1,-0.94 1.94,-1.72 0.69,-0.65 0.89,-0.88 1.68,-1.65 1.16,-1.13 2.23,-2.3 3.44,-3.51l20.56 -20.59c4.68,-4.65 9.08,-9.12 13.76,-13.77 2.4,-2.39 4.55,-4.6 6.97,-6.95 1.51,-1.47 5.47,-6.01 7.01,-6.64 1.64,1.18 21.59,21.26 22.02,22.12 -9.19,9.6 -19.8,19.41 -28.99,29.03l-38.49 38.65c-1.69,1.69 -3.15,3.15 -4.84,4.84 -1.4,1.4 -3.52,3.28 -4.62,4.7 -1.57,-0.51 -12,-11.46 -13.38,-12.85l-39 -38.99c0.81,-1.29 9.25,-9.42 11.05,-11.23 1.93,-1.95 3.64,-3.67 5.59,-5.6 1.2,-1.18 4.33,-4.78 5.68,-5.32l29.63 29.46zm117.38 -4.57c1.16,-15.96 -1.88,-32.04 -7.05,-45.28 -4.02,-10.31 -12.46,-24.77 -20.33,-32.61 -5.09,-5.07 -7.53,-8.06 -13.87,-12.75 -16.62,-12.3 -34.89,-20.08 -57.36,-21.55 -28.77,-1.89 -53.45,7.23 -74.65,24.25 -10.57,8.49 -20.13,20.84 -26.44,32.88 -16.89,32.24 -15.2,74.23 4.59,104.66 1.79,2.75 3.54,5.36 5.46,7.85 3.17,4.12 5.69,6.95 9.22,10.44 3.98,3.93 5.83,5.71 10.61,9.36 16.14,12.36 35.58,20.06 57.48,21.54 16.03,1.08 32.23,-1.79 45.51,-7.09 13.96,-5.57 21.87,-11.2 32.62,-20.33 1.16,-0.98 2.29,-2.27 3.35,-3.31 18.14,-17.83 28.93,-41.55 30.86,-68.06z">
      </path>
    </g>
  </svg>
</template>

<script>
export default { name: 'finished-icon' };
</script>
