<template>
  <div>
    <mobile-view v-if="$vuetify.breakpoint.xs" :steps="steps" />
    <v-app-bar v-else elevate-on-scroll fixed app>
      <a href="https://villaweek.com" class="logo-container">
        <v-icon size="42" class="logo">$VWLogo</v-icon>
      </a>
      <div class="navbar-content">
        <router-link
          v-for="{ name, svg, icon, enabled = true } in steps"
          :key="name"
          :to="{ name }"
          :class="{ 'disable-click': !enabled }">
          <v-icon :size="svg ? 24 : 30">
            {{ icon ? `mdi-${icon}` : `$${svg}` }}
          </v-icon>
        </router-link>
      </div>
      <user-menu />
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import MobileView from './Mobile';
import UserMenu from './UserMenu';

export default {
  name: 'navbar',
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('bookings', ['filter', 'stepValidation']),
    ...mapGetters('bookings', ['price']),
    isPackage: vm => vm.filter.outsidePackage || vm.$route.name === 'packages',
    steps() {
      const { stepValidation: enabled, isPackage, price } = this;
      const common = [
        { name: 'amenities', icon: 'hand-heart', enabled: enabled[6] },
        { name: 'experiences', icon: 'auto-fix', enabled: enabled[7] },
        { name: 'confirm-booking', svg: 'finished', enabled: enabled[8] && price }
      ];
      if (isPackage) return [{ name: 'packages', svg: 'logoOneColor' }, ...common];
      return [
        { name: 'locations', icon: 'earth' },
        { name: 'week-type', svg: 'logoOneColor', enabled: enabled[1] },
        { name: 'room-type', svg: 'lock', enabled: enabled[2] },
        { name: 'bookable-week', svg: 'calendar', enabled: enabled[3] },
        { name: 'crew-selection', icon: 'account-group', enabled: enabled[4] },
        { name: 'accommodations', svg: 'accommodation', enabled: enabled[5] },
        ...common
      ];
    }
  },
  components: { MobileView, UserMenu }
};
</script>

<style lang="scss" scoped>
.disable-click:not(.router-link-active) {
  pointer-events: none;
  opacity: 0.65;
}

::v-deep {
  .v-toolbar.v-app-bar.v-sheet, .v-sheet.navbar-content {
    background: $black;
  }

  svg {
    fill: $white;

    &:focus {
      outline: none;
    }
  }
}

::v-deep .logo-container {
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;

  .logo svg {
    .fil1 {
      fill: $white;
    }

    .fil0 {
      fill: url(#id0);
    }

    &:hover .fil0 {
      fill: $primary;
    }
  }
}

.navbar-content {
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  max-width: 62.5rem;
  margin: 0 auto;

  .v-icon {
    color: $white;
    line-height: 1.625rem;
  }

  a {
    color: $light-grey;
    text-decoration: none;
  }

  .router-link-exact-active {
    .v-icon {
      color: $primary;
    }

    ::v-deep svg {
      fill: $primary;
    }
  }
}
</style>
