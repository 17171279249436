<template>
  <vw-dialog v-model="show" :title="modalMock.included.title">
    <template #content>
      <p class="text-body-2">{{ modalMock.included.message }}</p>
      <template v-if="includedAmenities.length">
        <h2 class="mb-4">Amenities</h2>
        <included-amenity-list :selected-amenities="includedAmenities" />
      </template>
      <template v-if="includedExperiences.length">
        <h2 class="my-4">Experiences</h2>
        <included-experience-list :selected-experiences="includedExperiences" />
      </template>
      <included :included="includedText" class="mt-8" />
    </template>
  </vw-dialog>
</template>

<script>
import Included from '@/main/components/common/Included';
import IncludedAmenityList from '@/main/components/common/IncludedAmenityList';
import IncludedExperienceList from '@/main/components/common/IncludedExperienceList';
import { MODAL_DATA } from '@/main/common/config';

export default {
  props: {
    showModal: { type: Boolean, default: false },
    includedText: { type: String, default: '' },
    includedExperiences: { type: Array, default: () => [] },
    includedAmenities: { type: Array, default: () => [] }
  },
  data: () => ({ modalMock: MODAL_DATA }),
  computed: {
    show: {
      get: vm => vm.showModal,
      set(val) {
        this.$emit('update:showModal', val);
      }
    }
  },
  components: { IncludedAmenityList, IncludedExperienceList, Included }
};
</script>
