<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="27.3282mm"
    height="28.6907mm"
    version="1.1"
    style="shape-rendering: geometricPrecision; text-rendering: geometricPrecision; image-rendering: optimizeQuality; fill-rule: evenodd; clip-rule: evenodd;"
    viewBox="0 0 598.34 628.17"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"></metadata>
      <path class="fil0" d="M178 0l0 29.83 233.57 0 0 -29.83 55.41 0 0 29.83 48.24 0c22.81,0 43.57,9.35 58.62,24.39l0.11 0.11c15.04,15.05 24.39,35.81 24.39,58.62l0 432.11c0,22.88 -9.35,43.67 -24.39,58.71 -15.05,15.05 -35.84,24.4 -58.72,24.4l-432.11 0c-22.75,0 -43.51,-9.34 -58.61,-24.4l-0.11 -0.11c-15.05,-15.1 -24.4,-35.85 -24.4,-58.61l0 -432.11c0,-22.88 9.35,-43.67 24.4,-58.72 15.04,-15.04 35.83,-24.39 58.71,-24.39l39.48 0 0 -29.83 55.41 0zm0 85.24l0 37.97 -55.41 0 0 -37.97 -39.48 0c-7.59,0 -14.52,3.13 -19.54,8.16 -5.03,5.03 -8.16,11.96 -8.16,19.55l0 65.75 487.52 0 0 -65.75c0,-7.63 -3.11,-14.57 -8.11,-19.6 -5.02,-5 -11.97,-8.11 -19.6,-8.11l-48.24 0 0 37.97 -55.41 0 0 -37.97 -233.57 0zm364.93 459.82l0 -310.95 -487.52 0 0 310.95c0,7.66 3.09,14.58 8.05,19.54l0.11 0.11c4.97,4.96 11.89,8.05 19.54,8.05l432.11 0c7.59,0 14.52,-3.13 19.55,-8.16 5.02,-5.03 8.16,-11.95 8.16,-19.54zm-440.79 -251.09l83.19 0 0 83.19 -83.19 0 0 -83.19zm306.48 0l83.19 0 0 83.19 -83.19 0 0 -83.19zm-153.24 0l83.19 0 0 83.19 -83.19 0 0 -83.19zm-153.24 135.73l83.19 0 0 83.19 -83.19 0 0 -83.19zm306.48 0l83.19 0 0 83.19 -83.19 0 0 -83.19zm-153.24 0l83.19 0 0 83.19 -83.19 0 0 -83.19z">
      </path>
    </g>
  </svg>
</template>

<script>
export default { name: 'calendar-icon' };
</script>
