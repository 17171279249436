<template>
  <div class="week-type-container">
    <div v-if="isLoading" class="progress-container">
      <v-progress-circular size="60" color="#cfd8dc" indeterminate />
    </div>
    <template v-else>
      <div class="header">
        <h1 class="text-title">Duration</h1>
        <h2 class="text-subtitle">
          Select one of the offered week duration types.
        </h2>
      </div>
      <div class="week-types">
        <week-type-card
          v-for="choice in weekTypes"
          :key="choice.name"
          v-bind="choice" />
      </div>
      <proceed-footer
        v-if="hasSelection"
        @proceed="$router.push({ name: 'room-type' })" />
    </template>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { IMG_URLS } from '@/main/common/config';
import ProceedFooter from '@/main/components/common/ProceedFooter';
import WeekTypeCard from './Card';

const weekTypes = () => [{
  type: 'WEEK',
  assets: [{ url: IMG_URLS.week }],
  name: 'Week',
  tagline: 'The original 8 day long all-inclusive holiday experience',
  description: 'SATURDAY to SATURDAY'
}, {
  type: 'WEEKEND',
  assets: [{ url: IMG_URLS.weekend }],
  name: 'Weekend',
  tagline: 'The best of our week-long program condensed into a themed 4 day weekend',
  description: 'THURSDAY to SUNDAY'
}];

export default {
  name: 'week-type',
  computed: {
    ...mapState(['isLoading']),
    ...mapState('bookings', ['filter']),
    hasSelection: vm => !!vm.filter.weekType,
    weekTypes
  },
  methods: {
    ...mapActions('weeks', ['fetch']),
    ...mapMutations(['setLoading'])
  },
  beforeRouteEnter(_to, _from, next) {
    next(vm => vm.setLoading());
  },
  created() {
    const { id: locationId } = this.filter.location;
    return this.fetch({ locationId });
  },
  components: { WeekTypeCard, ProceedFooter }
};
</script>

<style lang="scss" scoped>
.week-type-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .week-types {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 1rem -1rem;

    .v-card {
      max-width: calc(100% - 2rem);
    }
  }
}
</style>
