import { VTextarea, VTextField } from 'vuetify/lib';

const INPUT = {
  TEXT: VTextField,
  RADIO_GROUP: 'radio-group',
  TEXTAREA: VTextarea
};

const guests = {
  type: INPUT.TEXTAREA,
  options: {
    label: 'Other Guests',
    placeholder: 'Please provide other guests names for informational purposes.'
  },
  validation: { min: 2, max: 200 }
};

export const formFields = isPackage => ({
  firstName: {
    type: INPUT.TEXT,
    options: { label: 'First Name' },
    validation: { required: true, min: 2, max: 50, name_format: true }
  },
  lastName: {
    type: INPUT.TEXT,
    options: { label: 'Last Name' },
    validation: { required: true, min: 2, max: 50, name_format: true }
  },
  email: {
    type: INPUT.TEXT,
    options: { label: 'Email' },
    validation: { required: true, email: true, min: 2, max: 50 }
  },
  country: {
    type: INPUT.TEXT,
    options: { label: 'Country' },
    validation: { required: true, min: 2, max: 50 }
  },
  phoneNumber: {
    type: INPUT.TEXT,
    options: { label: 'Phone Number' },
    validation: { required: true, phone_format: true, min: 2, max: 50 }
  },
  gender: {
    type: INPUT.RADIO_GROUP,
    options: {
      label: 'Gender',
      radios: [
        { name: 'Male', value: 'MALE' },
        { name: 'Female', value: 'FEMALE' },
        { name: 'Other', value: 'OTHER' }
      ]
    },
    validation: { required: true }
  },
  ...!isPackage && { guests }
});
