import generateActions from '@/common/store/helpers/actions';
const { api, get, save, remove } = generateActions('/locations');

const fetch = async ({ commit }) => {
  const items = await api.fetch({ withWeeks: true });
  commit('fetch', items);
  commit('setLoading', false, { root: true });
};

export { get, fetch, save, remove };
