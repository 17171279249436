<template>
  <div class="child-experiences-container">
    <h3 class="sub-exp-heading">{{ formattedHeading }}</h3>
    <div
      v-for="(exp, index) in experiences"
      :key="exp.id"
      :class="{ 'pt-1': !index }"
      class="experience">
      <div class="information">
        <v-checkbox color="cyan" input-value="true" dense class="checkbox" />
        <h2 class="exp-name">{{ exp.name | truncate(25) }}</h2>
        <v-chip x-small class="readonly ml-2 mr-0">
          <p class="chip-text">{{ exp.category.name }}</p>
        </v-chip>
        <v-icon @click="showAdditionalInfoDialog(exp)" color="cyan" class="info-icon">
          mdi-information-outline
        </v-icon>
      </div>
    </div>
    <vw-dialog
      v-if="showInfoDialog"
      v-model="showInfoDialog"
      :title="parentName">
      <template #content>
        <included-experiences
          :selected-experiences="[selectedExp]"
          is-dialog-experience />
        <p class="mt-4">{{ selectedExp.description }}</p>
      </template>
    </vw-dialog>
    <v-divider class="mt-2 mb-0 mx-3" />
  </div>
</template>

<script>
import IncludedExperiences from '@/main/components/common/IncludedExperienceList';
import pluralize from 'pluralize';

export default {
  props: {
    parentName: { type: String, default: '' },
    experiences: { type: Array, default: () => [] }
  },
  data: () => ({ showInfoDialog: false, selectedExp: {} }),
  computed: {
    formattedHeading() {
      const pluralizedExp = pluralize('experience', this.experiences.length);
      return `Additional ${pluralizedExp} included in the price`;
    }
  },
  methods: {
    showAdditionalInfoDialog(exp) {
      this.showInfoDialog = true;
      this.selectedExp = exp;
    }
  },
  components: { IncludedExperiences }
};
</script>

<style lang="scss" scoped>
.child-experiences-container .checkbox {
  height: 2.4375rem;
  margin: 0.35rem 0 0 0;
  pointer-events: none;
  opacity: 0.3;
}

.child-experiences-container .experience {
  padding: 0 1rem !important;
}

.info-icon {
  margin-right: 0.375rem;
}
</style>
