<template>
  <div class="week-card">
    <vw-card
      @click="updateFilterAndProceed"
      v-bind="{ assets, hasAvailableCapacity, isSelected }">
      <template #body>
        <v-chip small class="readonly">
          <p class="chip-text">{{ type }}</p>
        </v-chip>
        <v-spacer />
        <div>
          <p class="text-title">{{ title }}</p>
          <p class="text-subtitle">{{ formattedDate }}</p>
          <p class="text-regular my-1">Available</p>
          <p class="text-regular">
            {{ availableSlotsPerWeek(villa) }} pax
            <template v-if="!hasFullAccommodationWeekCapacity">left </template>
            <span class="text-regular text-lowercase">in</span>
            {{ formatAvailability(villa.rooms.length, 'bedroom') }}
          </p>
        </div>
      </template>
      <template #footer>
        <p class="text-regular">from {{ minPrice }} per person</p>
        <div class="included-info mt-2">
          <v-icon class="info-icon">$info</v-icon>
          <span @click="setContextAndOpen" class="text-regular secondary--text">
            What's already in the price?
          </span>
        </div>
      </template>
    </vw-card>
    <included-modal
      :show-modal.sync="showIncludedModal"
      :included-text="includedText"
      :included-experiences="includedExperiences"
      :included-amenities="includedAmenities" />
  </div>
</template>

<script>
import {
  adjustPrice,
  currencyFormatter,
  formatAvailability
} from '@/main/common/utils';
import { mapGetters, mapMutations, mapState } from 'vuex';
import get from 'lodash/get';
import IncludedModal from './IncludedModal';
import { PREMIUM } from '../../common/config';

export default {
  name: 'bookable-week-card',
  props: {
    isWeekend: { type: Boolean, default: false },
    start: { type: String, required: true },
    end: { type: String, required: true },
    week: { type: Object, required: true },
    villa: { type: Object, required: true },
    year: { type: Number, default: null },
    name: { type: String, default: null },
    assets: { type: Array, required: true }
  },
  data: () => ({
    showIncludedModal: false,
    includedText: '',
    includedExperiences: [],
    includedAmenities: []
  }),
  computed: {
    ...mapState('bookings', ['filter']),
    ...mapGetters('weeks', ['availableSlotsPerWeek']),
    isPrivateAccomodation: vm => vm.filter.roomType === 'PRIVATE',
    title: vm => `${vm.filter.weekType} ${vm.week.week} - ${vm.year}`,
    type: vm => vm.isWeekend ? vm.week.name : vm.villa.name,
    hasFullAccommodationWeekCapacity() {
      return this.availableSlotsPerWeek(this.villa) === this.villa.slots;
    },
    isSelected() {
      const { filter, villa } = this;
      const bookedVillaId = get(filter, 'villa.id');
      const bookedWeekId = get(filter, 'week.id');
      return bookedVillaId === villa.id && bookedWeekId === villa.week.id;
    },
    hasAvailableCapacity() {
      const { isPrivateAccomodation, hasFullAccommodationWeekCapacity, villa } = this;
      if (!isPrivateAccomodation) return this.availableSlotsPerWeek(villa);
      return hasFullAccommodationWeekCapacity;
    },
    formattedDate() {
      const { $options, start, end } = this;
      const { formatDate } = $options.filters;
      return `${formatDate(start, 'MMMM Do')} - ${formatDate(end)}`;
    },
    minPrice() {
      const { villa, isPrivateAccomodation } = this;
      const price = adjustPrice(villa, isPrivateAccomodation ? PREMIUM : 0);
      return currencyFormatter({ digits: 0 }).format(price);
    }
  },
  methods: {
    ...mapMutations('bookings', [
      'updateFilter', 'setFullVillaCapacity', 'setDefaultsForStep'
    ]),
    formatAvailability,
    updateFilterAndProceed() {
      const { villa, isPrivateAccomodation, isSelected, week } = this;
      if (isSelected) return this.$router.push({ name: 'crew-selection' });
      this.setDefaultsForStep({ step: 3 });
      this.updateFilter({ villa, week, formattedDate: this.formattedDate });
      if (isPrivateAccomodation) this.setFullVillaCapacity(villa);
      return this.$router.push({ name: 'crew-selection' });
    },
    setContextAndOpen() {
      const { included, experiences, amenities } = this.week;
      this.includedText = included;
      this.includedExperiences = experiences.filter(it => it.included);
      this.includedAmenities = amenities.filter(it => it.included);
      this.showIncludedModal = true;
    }
  },
  components: { IncludedModal }
};
</script>

<style lang="scss" scoped>
.week-card {
  max-width: 100%;
}

.included-info {
  display: flex;
  flex-direction: row;
  cursor: pointer;

  ::v-deep .info-icon {
    width: 1.1875rem;
    height: 1.125rem;
    margin-right: 0.5rem;
    fill: $secondary;
    outline: none;
  }

  .text-regular {
    font-weight: 600;
  }
}

::v-deep .v-responsive__content {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

::v-deep .v-chip {
  margin-top: 0.75rem;

  .v-chip__content .chip-text {
    margin: 0;
    color: $white;
    font-weight: 600;
    letter-spacing: 0.0625rem;
  }
}
</style>
